@import "device-width-constants";

.cdk-overlay-dark-backdrop {
  background: #314077;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  background-color: rgba(49, 64, 119, 0.6);
  opacity: 1;
}

.cdk-global-overlay-wrapper .mat-dialog-container {
  border-radius: 2rem;
  padding: 0;
  overflow: hidden;
}

.modal-instant-request {
  max-width: 60rem !important;
  max-width: 90vw !important;
}

.modal-bankcards {
  max-width: 90rem !important;
  overflow: auto;
}

@media #{$mobile} {
  .modal-bankcards {
    max-width: 90vw !important;
  }
}

.modal-instant-request-cancel {
  max-width: 60rem !important;
  max-width: 90vw !important;
}

.mat-dialog-container {
  position: relative;
  max-width: 90vw !important;
  margin: 0 auto;
}

.cdk-global-overlay-wrapper .modal-lesson-chat__preview .mat-dialog-container {
  box-shadow: none !important;
  ;
  background: transparent !important;
  padding: 6rem 0rem 0rem 0rem;
}

@media #{$mobile} {
  .mat-dialog-container {
    width: auto !important;
  }
}

.modal-messages-members {
  width: 43rem !important;
  max-width: 90vw !important;
}

.modal-lesson-finished {
  width: 60rem !important;
  max-width: 90vw !important;
}

.modal-lesson-done {
  max-width: 90vw !important;
  min-width: 60rem;
}

@media #{$mobile} {
  .modal-lesson-done {
    min-width: auto;
  }
}

.modal-messages-files {
  width: 43rem !important;
  max-width: 90vw !important;
}

.modal-messages-images {
  width: 84rem !important;
  max-width: 90vw !important;
}

.modal-messages-images .images {
  display: flex;
  flex-wrap: wrap;
}

.modal-messages-images .images .image {
  width: 12rem;
  height: 12rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.modal-messages-images .images .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.modal-reclamation {
  width: 60rem !important;
  max-width: 90vw !important;
}

.modal-book {
  width: 60rem !important;
  max-width: 90vw !important;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  overflow: auto;
}

.modal__cardlist {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 2rem 0;
  margin-top: 2rem;
}

.modal__cardlist .profile_card {
  margin-bottom: 0;
}

.modal__cardlist .profile_cardlist {
  overflow: auto;
  width: 100%;
  height: 24rem;
}

.modal__cardlist .profile_cardlist__wrap {
  white-space: nowrap;
  display: flex;
}

.modal__textarea,
.modal .mat-form-field {
  width: 100%;
}

.modal_checks {
  display: flex;
  flex-wrap: wrap;
}

.modal_checks__item {
  margin-bottom: 2rem;
  padding-right: 2rem;
}

.modal__body {
  border-radius: 2rem;
  background: #fff;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.modal__header {
  height: 16rem;
  background: url('/assets/img/content/bg_modal_header.jpg') no-repeat 50% 50% / cover;
  position: relative;
}

@media #{$mobile} {
  .modal__header {
    height: 10rem;
  }
}

.modal_header {
  border-bottom: 1px solid #979797;
  padding: 7rem 7rem 2.5rem;
}

@media #{$mobile} {
  .modal_header {
    padding: 3rem;
  }
}

.modal_header__text {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5em;
}

.modal_header__title {
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 2.3rem;
  margin-bottom: 3rem;
  text-align: center;
}

.modal_header__hint {
  font-size: 1.8rem;
  color: #6d6d7e;
  text-align: center;
}

.modal__icon {
  position: absolute;
  top: 11.2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

@media #{$mobile} {
  .modal__icon {
    top: 6rem;
  }
}

.modal__icon img {
  width: 9.4rem;
  height: 9.2rem;
}

@media #{$mobile} {
  .modal__icon img {
    width: 6rem;
    height: 6rem;
  }
}

.modal__content {
  padding: 2rem 5rem;
}

@media #{$mobile} {
  .modal__content {
    padding: 2rem 1rem;
  }
}

.modal__content--icon {
  padding-top: 7rem;
}

@media #{$mobile} {
  .modal__content--icon {
    padding-top: 3rem;
  }
}

.modal__title {
  font-weight: bold;
  font-size: 3.2rem;
  text-align: center;
  line-height: 4rem;
  margin: 0 auto 1.5rem;
  width: 40rem;
}

@media #{$mobile} {
  .modal__title {
    width: 100%;
    font-size: 2.4rem;
    line-height: 3rem;
  }
}

.modal__text {
  font-size: 1.6rem;
  color: #44444f;
  line-height: 2.5rem;
  text-align: center;
}

.modal__actions {
  text-align: center;
  margin: 4rem 0 2rem;
}

@media #{$mobile} {
  .modal__actions {
    margin: 2rem 0 0;
  }

  .modal__actions .btn {
    width: 100%;
  }

  .modal__actions .btn__label {
    width: 100%;
  }
}

.modal__actions .btn+.btn {
  margin-left: 2rem;
}

@media #{$mobile} {
  .modal__actions .btn+.btn {
    margin-left: 0;
    margin-top: 1rem;
  }
}

.modal__close {
  width: 3.5rem;
  height: 3.3rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
  z-index: 2;
}

.modal__close img {
  width: 3.5rem;
  height: 3.3rem;
}

.messages_files {
  padding: 3rem;
}

.messages_files__title {
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.4rem;
  margin-bottom: 2rem;
}

.messages_files__list {
  overflow: auto;
  max-height: 21rem;
}

.messages_files__list .file {
  border: 1px solid #eceef5;
  justify-content: space-between;
  height: 5.6rem;
  display: flex;
  border-radius: 1rem;
  background: #fff;
  align-items: center;
  text-align: left;
}

.messages_files__list .file+.file {
  margin-top: 2rem;
}

.messages_files__list .file__cloud {
  margin-right: 1rem;
}

.messages_files__list .file__cloud img {
  width: 3.6rem;
  height: 3.6rem;
}

.messages_files__list .file__icon {
  background: #eff3ff;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.messages_files__list .file__icon img {
  max-width: 2rem;
  max-height: 2rem;
}

.messages_files__list .file__info {
  margin-right: 1rem;
  flex: 1 1 auto;
}

.messages_files__list .file__name {
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 18rem;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.messages_files__list .file__size {
  font-size: 1.2rem;
  color: #92929d;
  display: block;
}

.modal_request_instant .modal__body {
  width: 60rem;
}

.modal-group-lesson-finish {
  min-width: 520px;
}

.cdk-overlay-pane {
  max-width: none !important;

  @media #{$mobile} {
    overflow: auto;
  }
  .mat-select-panel-wrap {    
      transform: translateX(16px);    
  }
}

.cdk-info-popup-mobile {
  top: 50% !important;
  left: 50% !important;
  bottom: auto !important;
  right: auto !important;
  transform: translate(-50%, -50%) !important;
}
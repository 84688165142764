.form__field {
  div {
    font-size: 1.8rem;
    .mat-form-field-ripple {
      display: none;
    }
    .mat-form-field-flex {
      padding: 1.3rem;
      border-radius: 5px;
    }
    .mat-form-field-infix {
      padding: 0;
      margin: 0;
      border: 0;
    }
    .mat-form-field-label-wrapper {
      padding: 0;
    }
  }
}
.mat-autocomplete-panel {
  max-height: 35rem !important;
}
.mat-form-field-underline {
  display: none;
}

.nav{
	height: 100%;
	.thumb{
		background: white;
	}
}
.nav_item {
	position: relative;
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	color: #fff;
	font-size: 16px;
	padding: 0px 16px;
	margin-bottom: 16px;

	.badge {
		background: #ed3434;
		width: 2.3rem;
		height: 1.5rem;
		text-align: center;
		font-size: 1rem;
		color: #fff;
		display: inline-block;
		border-radius: 1.2rem;
		line-height: 1.5rem;
		font-weight: 400 !important;
		margin-left: 0.5rem;
		vertical-align: middle;

		&.collapsed {
			margin-left: 0 !important;
			margin-top: 0 !important;
			position: absolute;
			top: -3px;
			left: 15px;
			display: inline-flex;
			width: 9px;
			height: 9px;
			border-radius: 50%;
			vertical-align: top;
			background: #ed3434;
		}
	}

	.nav_item__name {
		text-wrap: nowrap;
	}

	&.active {
		color: #678aff;

		&:before {
			width: 0.3rem;
			height: 100%;
			border-radius: 0 0.3rem 0.3rem 0;
			background: #678aff;
			content: '';
			position: absolute;
			left: 0;
			top: 0;
		}

		.nav_item__name {
			font-weight: bold;
		}
	}
}

.nav_item__icon {
	position: relative;
	width: 28px;
	height: 28px;
	flex-shrink: 0;

	&.collapsed {
		margin-right: 0;
	}
}

.nav_item--red {
	color: #ed3434;
	font-weight: bold;

	&.active {
		color: #ed3434;

		&:before {
			background: #ed3434;
		}
	}
}

.nav_line {
	width: 5.5rem;
	height: 1px;
	background: #f1f1f5;
	opacity: 0.5;
	margin: 1.5rem auto;
}

.active-lessons-collapsed,
.unread-messages-collapsed {
	margin-left: 0 !important;
	margin-top: 0 !important;
	position: absolute;
	top: -3px;
	left: 15px;
}
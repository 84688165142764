.dark-snackbar {
  background: #44444f;
  border-radius: 8px !important;
  color: #fff;
  max-width: 500px !important;
  padding: 12px 18px !important;
}
.ezteach-snackbar {
  padding: 0;
  background: transparent;
  box-shadow: unset;
}

.fc-timeGridWeek-view .fc-scrollgrid-section.fc-scrollgrid-section-header td {
  border: none !important;
}
.fc-timeGridWeek-view .fc-scrollgrid-section.fc-scrollgrid-section-body td {
  border-top: none !important;
}
.calendar_button_wrapper {
  position: absolute;
  top: 4rem;
  left: 25rem;
  z-index: 15;
}
.open-popup-container{
  
  .discipline {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: #92929D;
    position: relative;
  
    margin-bottom: 12px;
  
    padding-right: 16px;
  
    .close {
      display: flex;
      cursor: pointer;
      width: 20px;
  
      scale: 0.7;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  
  .theme {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #44444F;
  
    max-height: 144px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 6;
  
    margin-bottom: 12px;
  }
  
  .publicLesson {
    width: 145px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  
    border-radius: 6px;
    border: 1px solid #3DD598;
  
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  
    background-color: #3DD598;
  }
  
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    flex-wrap: wrap;
  
    font-size: 14px;
    font-weight: 400;
    color: #92929D;
  
    margin-bottom: 24px;
  
    .date-time {
      display: flex;
      align-items: center;
      gap: 8px;
  
      width: 100%;
  
      margin-bottom: 12px;
  
      .time {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  
    .type-privacy {
      display: flex;
      align-items: center;
      gap: 20px;
  
      width: 100%;
    }
  
    .date+.time {
      &:before {
        content: "";
  
        width: 4px;
        height: 4px;
  
        display: block;
  
        background-color: #678AFF;
        border-radius: 100%;
      }
    }
  }
  
  .members-wrapper {
    display: flex;
    flex-direction: column;
  
    &+& {
      margin-top: 24px;
    }
  
    .members {
      display: flex;
      align-items: center;
      gap: 8px;
  
      margin-bottom: 12px;
  
      .icon {
        width: 16px;
        height: 16px;
      }
    }
  
    .members-list {
      max-height: 54px;
      overflow: hidden;
      text-overflow: ellipsis;
      line-clamp: 3;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #44444F;
    }
  }
  
  .control {
    margin-top: 24px;
    button{
      background: #678AFF;
      color: #FFFFFF;
      border-radius: 7px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.1px;
      display: flex;
      align-items: center;
      justify-content: center;
      width:100%;
      height: 38px;
    }
  }
  
  .text-title {
    line-height: 14px;
    font-weight: 500;
    color: #92929D;
  }
  }
@media screen and (max-width: 1279.9px) {
  .calendar_button_wrapper {
    left: 0;
    top: 8rem;
  }
  .calendar_button_wrapper.sticky {
    position: fixed;
    left: 1.5rem;
    top: 13.5rem;
    background-color: #fff;
  }
}
@media action {
  .calendar_button_wrapper.sticky {
    position: fixed;
    top: 9rem;
    left: 56rem;
  }
}
.round--btn_wrapper {
  position: absolute;
  top: 50%;
}
.round--btn_wrapper .round--btn__inner-wrapper {
  position: relative;
}
.calendar_button {
  position: relative;
  margin-left: 6rem;
  border-radius: 7px;
  cursor: pointer;
}
calendar_button_wrappe
.calendar_button.calendar_button-clicked .round--btn {
  background: #fff;
}
.calendar_button.calendar_button-clicked .round--btn::before {
  content: '';
  border: 2px solid #678aff;
  transform: rotate(45deg);
  background: #678aff;
  animation: spin 0.5s linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.calendar_button.calendar_button-clicked .round--btn::after {
  content: '';
  transform: rotate(45deg);
  border: 2px solid #678aff;
  background: #678aff;
  animation: spin 0.5s linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@-moz-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@-o-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@media screen and (max-width: 1279.9px) {
  .calendar_button {
    margin-left: 3rem;
  }
}
.calendar_button .btn--border--blue {
  background: #fffef6;
  border: 1px solid #678aff;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}
.calendar_button .btn--border--blue .btn__label-blue {
  color: #678aff;
}
@media screen and (max-width: 1279.9px) {
  .calendar_button .btn--border--blue {
    padding-right: 0.5rem;
  }
}
@media screen and (max-width: 1279.9px) {
  .calendar_button .btn--border--blue {
    width: 20rem;
    font-size: 1.2rem;
    padding-left: 2.5rem;
  }
}
.calendar_button .round--btn {
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  margin-top: -24px;
  left: 0;
  margin-left: -24px;
  height: 48px;
  width: 48px;
  border: 1px solid #678aff;
  border-radius: 50%;
  background: #678aff;
  z-index: 50;
}
.calendar_button .round--btn::before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1.5px;
  margin-top: -9.5px;
  content: '';
  border: 2px solid #fff;
  height: 16px;
  width: 0;
  background: #fff;
}
.calendar_button .round--btn::after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -9.5px;
  margin-top: -1.5px;
  content: '';
  width: 16px;
  height: 0;
  border: 2px solid #fff;
  background: #fff;
}
.calendar {
  background: #fffef7;
  border-radius: 2rem;
  padding: 2rem 3rem;
  margin-bottom: 2rem;
  position: relative;
}
.calendar.dayGridMonth .fc-button.fc-top,
.calendar.dayGridMonth .fc-button.fc-bottom {
  display: none !important;
}
.calendar .fc-header-toolbar {
  box-sizing: border-box;
  height: 10rem;
}
.calendar .fc-header-toolbar .fc-toolbar-chunk .fc-today-button {
  background: transparent !important;
  border: none;
  color: #000;
}
.calendar .fc-header-toolbar .fc-toolbar-chunk:disabled {
  color: #aaa;
}
.calendar .fc-scroller-harness-liquid {
  border-top: none !important;
}
.calendar .timeGridType_wrapper {
  display: flex;
  align-items: baseline;
  position: absolute;
  right: 22%;
}
@media screen and (max-width: 1279.9px) {
  .calendar .timeGridType_wrapper {
    top: 2.2rem;
    right: 23%;
  }
}
@media screen and (max-width: 1279.9px) {
  .calendar .timeGridType_wrapper {
    top: 8rem;
    right: 1rem;
  }
}
.calendar .timeGridType_wrapper.sticky {
  position: fixed;
  top: 9.5rem;
  right: 19%;
}
@media screen and (max-width: 1279.9px) {
  .calendar .timeGridType_wrapper.sticky {
    position: fixed;
    top: 13.5rem;
    right: 2.5rem;
    background-color: #fff;
  }
}
.calendar .timeGridType_wrapper.calendar_button-clicked {
  background-color: #678aff;
}
.calendar .timeGridType_wrapper span {
  margin-right: 1rem;
  font-weight: 400;
}
@media screen and (max-width: 1279.9px) {
  .calendar .timeGridType_wrapper {
    font-size: 14px;
  }
  .calendar .timeGridType_wrapper .mat-form-field-infix {
    margin-left: 0.5rem;
  }
  .calendar .timeGridType_wrapper .timeGridType_text {
    display: none;
  }
}
.calendar .timeGridType_wrapper .mat-form-field {
  width: 9rem;
  font-weight: 500;
}
.calendar .temp {
  position: absolute;
  top: 5rem;
  left: 20rem;
  border-radius: 1rem;
}
.calendar.calendar-edit-mode {
  touch-action: none;
  background: linear-gradient(to bottom, #678aff 7%, #fffef7 7% 100%);
}
.calendar.calendar-edit-mode.dayGridMonth {
  background: linear-gradient(to bottom, #678aff 17%, #fffef7 17% 100%);
}
.calendar.calendar-edit-mode .calendar_el.stickyToolbar .fc-header-toolbar {
  background: #678aff;
}
.calendar.calendar-edit-mode .fc-header-toolbar {
  background: #678aff;
}
.calendar.calendar-edit-mode .icon-drag {
  color: #fff;
}
.calendar.calendar-edit-mode .timeGridType_wrapper {
  color: #fff;
}
.calendar.calendar-edit-mode .timeGridType_wrapper .mat-select-value-text {
  color: #fff;
}
.calendar.calendar-edit-mode .timeGridType_wrapper .mat-select-arrow {
  color: #fff;
}
.calendar.calendar-edit-mode .fc-today-button {
  color: #fff;
  background: #678aff;
}
.calendar.calendar-edit-mode .fc-button {
  color: #fff;
  background: #678aff;
  border-radius: 1px solid #fff;
}
.calendar.calendar-edit-mode .fc-button.fc-button-active {
  color: #678aff;
  background: #fff !important;
}
.calendar.calendar-edit-mode .fc-button.fc-prev-button:not(.fc-top),
.calendar.calendar-edit-mode .fc-button.fc-next-button:not(.fc-bottom) {
  background: #678aff;
}
.calendar.calendar-edit-mode .fc-button.fc-prev-button:not(.fc-top) .fc-icon-left-single-arrow,
.calendar.calendar-edit-mode .fc-button.fc-next-button:not(.fc-bottom) .fc-icon-left-single-arrow {
  background: url('/assets/img/content/arrow-left-white.svg') no-repeat;
}
.calendar.calendar-edit-mode .fc-button.fc-prev-button:not(.fc-top) .fc-icon-right-single-arrow,
.calendar.calendar-edit-mode .fc-button.fc-next-button:not(.fc-bottom) .fc-icon-right-single-arrow {
  background: url('/assets/img/content/arrow-right-white.svg') no-repeat;
}
.calendar.calendar-edit-mode .fc-day-today .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
  background: #678aff;
  color: #fff;
}
.calendar.calendar-edit-mode .fc-day-today .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion span {
  color: #678aff;
}
.calendar.calendar-edit-mode .fc-toolbar-title {
  color: #fff;
}
.calendar.calendar-edit-mode .fc-col-header-cell-cushion {
  background: #fff;
}
.calendar.calendar-edit-mode.inserted {
  background: linear-gradient(to bottom, #678aff 15%, #fffef7 15% 100%);
}
.calendar.calendar-edit-mode.inserted .fc-scroller {
  overflow: hidden !important;
}
.calendar.calendar-edit-mode .fc-col-header-cell-cushion {
  background: #fff;
}
.calendar.calendar-edit-mode .fc-day-today .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
  background: #678aff;
  color: #fff;
}
.calendar.calendar-edit-mode .fc-day-today .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion span {
  color: #fff;
}
.calendar .fc-col-header-cell .fc-col-header-cell-cushion,
.calendar .fc-day .fc-col-header-cell-cushion {
  margin-left: 0;
  width: 60px;
}
.calendar .fc-col-header-cell-cushion {
  cursor: default;
}
.calendar .fc-col-header colgroup col,
.calendar .fc-timegrid-slots colgroup col,
.calendar .fc-timegrid-cols colgroup col {
  width: 11.6rem !important;
}
.calendar .fc-col-header .mobile-time-range col,
.calendar .fc-timegrid-slots .mobile-time-range col,
.calendar .fc-timegrid-cols .mobile-time-range col {
  width: 8rem !important;
}
.calendar .fc-direction-ltr .fc-timegrid-slot-label-frame {
  text-align: center !important;
}
.calendar .fc-timegrid-slot-label {
  background: #fffef7;
  border: 1px solid #fffef7 !important;
  border-right: 1px solid transparent !important;
}
.calendar .fc-timegrid-now-indicator-arrow {
  display: none;
}
@media screen and (max-width: 1279.9px) {
  .calendar {
    padding: 1.5rem 2rem;
  }
  .calendar .fc-toolbar-chunk:last-child {
    white-space: nowrap;
  }
}
.calendar .fc-toolbar-chunk:first-child {
  display: flex;
  flex-direction: column;
}
.calendar .fc-toolbar-chunk:first-child .fc-toolbar-title {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: bold;
}
.calendar .fc-toolbar-chunk:first-child .fc-toolbar-title::first-letter {
  text-transform: uppercase;
}
@media screen and (max-width: 1279.9px) {
  .calendar .fc-toolbar-chunk:first-child .fc-toolbar-title {
    font-size: 1.5rem;
  }
}
.calendar .fc-timegrid-slots tr td {
  height: 3rem;
  border: none;
}
.calendar .fc-timegrid-slots tr .fc-timegrid-slot {
  border-top: 1px dashed #e2e2ea;
}
.calendar .fc-timegrid-slots tr .fc-timegrid-slot.fc-timegrid-slot-label {
  color: #92929d;
  vertical-align: top;
}
.calendar .fc-timegrid-slots table tbody tr {
  border-top: 1px dashed #e2e2ea !important;
}
.calendar .fc-timegrid-axis-frame {
  width: 5rem;
}
.calendar .fc .fc-axis {
  padding-right: 2rem !important;
}
.calendar .fc-col-header {
  margin: 1rem 0 2rem;
}
.calendar .fc-col-header .fc-day-past .fc-col-header-cell-cushion {
  background: #92929d;
  color: #fff;
  opacity: 0.5;
}
.calendar .fc-col-header .fc-day-past .fc-col-header-cell-cushion span {
  color: #fff;
}
.calendar .fc-col-header th {
  border-color: transparent;
}
.calendar .fc .fc-scrollgrid {
  border: 0;
}
.calendar .fc .fc-scrollgrid-section > td {
  border-bottom-color: #fffef7;
}
.calendar .fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
  background: #fffef7;
  top: calc(var(--headerHeight) + 10rem);
  border-bottom: 1px solid #e2e2ea;
}
@media screen and (max-width: 1279.9px) {
  .calendar .fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > *.sticky {
    position: fixed;
    top: 20rem;
  }
}
.calendar .calendar_el {
  position: relative;
}
.calendar .calendar_el.stickyToolbar .fc-header-toolbar {
  position: sticky;
  top: var(--headerHeight);
  z-index: 10;
  background: #fffef7;
  margin-right: -3rem;
  padding-right: 3rem;
  margin-left: -3rem;
  padding-left: 3rem;
}
@media screen and (max-width: 1279.9px) {
  .calendar .calendar_el.stickyToolbar .fc-header-toolbar {
    margin-right: -1.5rem;
    padding-right: 1.5rem;
    margin-left: -1.5rem;
    padding-left: 1.5rem;
  }
}
@media screen and (max-width: 1279.9px) {
  .calendar .calendar_el.stickyToolbar .fc-header-toolbar {
    display: flex;
    align-items: flex-start;
    height: 13rem;
    margin-right: -2rem;
    padding-right: 1.5rem;
    margin-left: -2rem;
    padding-left: 1.5rem;
    padding-top: 2rem;
    margin-bottom: 0;
  }
  .calendar .calendar_el.stickyToolbar .fc-header-toolbar.sticky {
    position: fixed;
    left: 1.5rem;
    right: 1.5rem;
    margin: 0;
  }
}
.calendar .calendar_el.calendarWidget {
  margin-bottom: 7rem;
}
.calendar .calendar_el.calendarWidget .fc-scroller-liquid-absolute {
  overflow: hidden !important;
}

.calendar .calendar_el.calendarWidget .fc-dayGridMonth-view .fc-scroller-liquid-absolute {
  overflow: scroll !important;
}
.calendar .fc-button.fc-top {
  width: 3.2rem;
  height: 3.2rem;
  position: absolute;
  top: 14rem;
}
@media screen and (max-width: 1279.9px) {
  .calendar .fc-button.fc-top {
    top: 15rem;
  }
}
.calendar .fc-button.fc-top .fc-icon {
  margin-left: -1.5px;
  width: 1.4rem;
  margin-bottom: 3px !important;
  height: 0.7rem;
  padding: 0.1rem 0 1.2rem;
  padding-right: 0.5rem;
  background: url('/assets/img/content/arrow-top.svg') no-repeat !important;
}
.calendar .fc-button.fc-bottom {
  width: 3.2rem;
  height: 3.2rem;
  position: absolute;
  top: 64.5rem;
}
.calendar .fc-button.fc-bottom .fc-icon {
  margin-left: -1.5px;
  width: 1.4rem;
  margin-bottom: 3px !important;
  height: 0.7rem;
  padding: 0.1rem 0 1.2rem;
  padding-right: 0.5rem;
  background: url('/assets/img/content/arrow-bottom.svg') no-repeat !important;
}
.calendar .fc-view-harness {
  margin: 0 -3rem;
}
@media screen and (max-width: 1279.9px) {
  .calendar .fc-view-harness {
    margin: 0 -1.5rem;
  }
}
.calendar .fc .fc-scroller-harness-liquid {
  border-top: 1px solid #ccc;
}
.calendar .fc .fc-col-header-cell-cushion {
  width: 5.8rem;
  height: 4.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2e2ea;
  border-radius: 1rem;
}
@media screen and (max-width: 1279.9px) {
  .calendar .fc .fc-col-header-cell-cushion {
    width: auto;
  }
}
.calendar .fc .fc-col-header-cell-cushion b {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.8rem;
}
.calendar .fc .fc-col-header-cell-cushion span {
  text-transform: uppercase;
  color: #92929d;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 400;
}
.calendar .fc .fc-col-header-cell.fc-day-today .fc-col-header-cell-cushion {
  background: #678aff;
  color: #fff;
  border-color: #678aff;
}
.calendar .fc .fc-col-header-cell.fc-day-today .fc-col-header-cell-cushion span {
  color: #fff;
}
.calendar .fc .fc-dayGridMonth-view .fc-col-header-cell-cushion {
  margin: 0 auto;
}
.calendar .fc .fc-timeGridWeek-view .fc-col-header-cell-cushion {
  font-size: 0;
}
.calendar .fc .fc-dayGridMonth-view .fc-col-header-cell-cushion {
  width: 13rem;
}
@media screen and (max-width: 1279.9px) {
  .calendar .fc .fc-dayGridMonth-view .fc-col-header-cell-cushion {
    width: auto;
  }
}
.calendar .fc .fc-dayGridMonth-view .fc-col-header-cell-cushion::first-letter {
  text-transform: uppercase;
}
.calendar .fc-scrollgrid-sync-table {
  margin-top: -1px;
}
.calendar .fc .fc-timegrid-now-indicator-container {
  overflow: inherit;
}
.calendar .fc .fc-timegrid-now-indicator-line {
  left: -1.2rem;
  border-color: #ed3434;
}
.calendar .fc .fc-timegrid-now-indicator-line:before {
  content: attr(data-now-indicator);
  position: absolute;
  width: 3.6rem;
  height: 1.5rem;
  left: -3.6rem;
  top: -0.75rem;
  background-color: #ed3434;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar .fc-pending {
  background: #678aff;
  border-radius: 0.9rem;
  position: absolute;
  color: #fff;
  top: -1.2rem;
  right: 3.4rem;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.3rem 1rem;
  text-transform: uppercase;
}
.calendar .fc-menu {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background: url('/assets/img/svg/calendar/edit.svg') no-repeat 50% 50%/1.5rem 1.5rem;
  position: absolute;
  top: 0px;
  right: 4px;
}
.calendar .fc-menu:hover {
  background-color: #e0e0e0;
}

.calendar .fc-start-block {
  display: none;
  &.displaying {
    display: flex;
    position: absolute;
    background: #678aff;
    left: 5px;
    top: 5px;
    border-radius: 3px;
    padding: 5px;
    color: #fff;
    // вычитаем ширину кнопки редактирования и отступы у start-block u fc-main
    width: calc(100% - 2.4rem - 9px);
    justify-content: center;
  }
}

.calendar .fc-menu-pending {
  width: 2.4rem;
  height: 2.2rem;
  border-radius: 50%;
  background: url('/assets/img/svg/calendar/to-accepted.svg') no-repeat 50% 50%/1.5rem 1.5rem;
  position: absolute;
  top: 3px;
  right: 4px;
}
.calendar .fc-approved {
  width: 2.4rem;
  height: 2.2rem;
  border-radius: 50%;
  background: url('/assets/img/svg/calendar/accepted.svg') no-repeat 50% 50%/1.5rem 1.5rem;
  position: absolute;
  top: 3px;
  right: 4px;
}
.calendar .fc-event {
  background: #f1f1f5;
  border-radius: 0.5rem 1.2rem 1.2rem 0.5rem;
  border: 0px;
  margin-left: 0 5;
}
.calendar .fc-event-main {
  position: static !important;
  z-index: 5;
}

.calendar .fc-slot-delete {
  position: absolute;
  right: 0.5rem;
  top: -1.2rem;
  background: url('/assets/img/content/icon-cal-delete.svg') no-repeat;
  display: block;
  width: 24px;
  height: 24px;
}
.calendar .fc-media-screen .fc-timegrid-event-harness {
  left: 0 !important;
  margin-right: 0 !important;
  z-index: auto !important;
  width: 100% !important;
}
.calendar .fc-event-slot {
  border-radius: 1.2rem;
  margin-left: 0;
  background: rgba(103, 138, 255, 0.2);
  border: 2px solid #678aff;
  opacity: 1;
}
.calendar .fc-event-slot.fc-event-past {
  opacity: 0.3;
}
.calendar .fc-highlight {
  border-radius: 1.2rem;
  margin-right: 0;
  margin-left: 2px;
  margin-right: 0px;
  background: rgba(103, 138, 255, 0.2);
  border: 2px solid #678aff;
  opacity: 1;
}
.calendar .fc-event-slot-edit {
  border-radius: 1.2rem;
  margin-right: -0.2rem;
  margin-left: 0;
  border: 2px solid #678aff;
}
.calendar .fc-event-slot-available {
  background: rgba(9, 204, 126, 0.102);
  border-radius: 1.2rem;
  border: 2px solid #09cc7e;
}
.calendar .fc-event-slot-available {
  margin-right: -0.2rem;
}
.calendar .fc-event-slot-available .fc-event-resizer-start:before {
  content: '';
  position: absolute;
  width: 9px;
  height: 9px;
  border: 1px solid #678aff;
  border-radius: 25px;
  left: 50%;
  transform: translate(-50%, 0);
  top: -1px;
  z-index: 23;
  background-color: #fff;
  cursor: n-resize;
}
.calendar .fc-event-slot-available .fc-event-resizer-end:before {
  content: '';
  position: absolute;
  width: 9px;
  height: 9px;
  border: 1px solid #678aff;
  border-radius: 25px;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0px;
  z-index: 23;
  background-color: #fff;
  cursor: s-resize;
}
.calendar .fc-event-slot-available-past {
  cursor: not-allowed !important;
}
.calendar .fc-event-slot-available-past .fc-event-resizer-start {
  display: none;
}
.calendar .fc-event-slot-available-past .fc-event-resizer-end {
  display: none;
}
.calendar .fc-title {
  font-size: 1.4rem;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 145px;
  min-height: 20px;
}
.calendar .fc-title .cal_popup {
  padding: 0;
}
.calendar .fc-time {
  font-size: 1.2rem;
  line-height: 2.4rem;
  color: #696974;
}
.calendar .fc-content {
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-radius: 0.5rem 1.2rem 1.2rem 0.5rem;
  &.d-none {
    visibility: hidden;
  }
  &.started {
    border: 1px solid rgb(61, 213, 152);
  }
}
.calendar .fc-event-lesson {
  z-index: 10;
  box-shadow: none;
}
.calendar .fc-event-lesson.fc-event-past {
  opacity: 1;
  cursor: not-allowed !important;
}
.calendar .fc-event-busy {
  z-index: 10;
  box-shadow: none;
  margin-left: -3px;
  background: #f1f1f5;
}
.calendar .fc-event-busy.fc-event-past {
  opacity: 1;
}
.calendar .fc-event-group {
  background: #f1f1f5;
  margin-right: -0.2rem;
}
.calendar .fc-event-onelesson {
  margin-right: 2px;
  margin-top: 2px;
  margin-left: 2px;
  margin-bottom: 2px;
  border-radius: 1.2rem;
}
.calendar .fc-event-onelesson:before {
  background: #3dd598;
  left: 6px;
}
.calendar .fc-event .fc-content {
  color: #000;
  padding: 0.2rem 1rem 0.5rem 1.5rem;
  font-size: 1.4rem;
  flex-direction: column;
  justify-content: center;
}
.calendar .fc-event .fc-event-time {
  order: 2;
  color: #696974;
}
.calendar .fc-event .fc-event-title-container {
  order: 1;
  flex: 0 0 auto;
  font-weight: 500;
  color: #44444f;
}
.calendar .fc-button {
  background: #fffef6;
  border-color: #e2e2ea;
  border-radius: 1rem !important;
  margin-left: 0.5rem;
  color: #000;
}
.calendar .fc-button.fc-top,
.calendar .fc-button.fc-bottom {
  background: #fffef7;
}
.calendar .fc-button.fc-button-active {
  border: none !important;
  background: #678aff !important;
}
.calendar .fc-button.fc-prev-button,
.calendar .fc-button.fc-next-button {
  height: 3rem;
  width: 3rem;
  margin-left: 1.2rem;
  background: #fff;
}
.calendar .fc-button.fc-prev-button .fc-icon,
.calendar .fc-button.fc-next-button .fc-icon {
  margin-bottom: 6px;
}
.calendar .fc-button.fc-prev-button {
  margin-right: 1rem;
}
.calendar .fc-button span {
  width: 0.7rem;
  height: 1.4rem;
}
.calendar .fc-button span.fc-icon-left-single-arrow {
  background: url('/assets/img/content/arrow-left.svg') no-repeat;
}
.calendar .fc-button span.fc-icon-right-single-arrow {
  background: url('/assets/img/content/arrow-right.svg') no-repeat;
}
.calendar .fc-button:hover {
  background: #fffef6;
  border-color: #ccc;
  color: #000;
}
.calendar .fc-button[disabled] {
  background: #fffef6 !important;
  border-color: #e2e2ea;
  color: #aaa;
}
.cal_popup {
  min-width: 16rem;
  padding: 1rem;
}
.cal_popup_error {
  display: none;
  margin-bottom: 1rem;
  color: #f00;
  font-size: 1.2rem;
}
.cal_popup .btn + .btn {
  margin-top: 1rem;
}
.cal_popup__title {
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
.cal_popup__warning {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  position: relative;
}
.cal_popup__warning__disabled {
  display: none;
}
.cal_popup__warning span {
  color: #ed3434;
  font-weight: 500;
  display: block;
  margin-bottom: 0.5rem;
}
.cal_popup__warning span .linkToPersonalCabinet {
  color: #ed3434;
  text-decoration: underline;
}
.cal_popup__warning span .linkToPersonalCabinet:hover {
  text-decoration: none;
}
.cal_popup__link {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  position: relative;
}
.cal_popup__link span {
  color: #999;
  font-weight: 500;
  display: block;
  margin-bottom: 0.5rem;
}
.cal_popup__val {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  position: relative;
}
.cal_popup__val .cal_popup__view {
  display: flex;
  align-items: center;
}
.cal_popup__val .cal_popup__view img {
  margin-right: 5px;
}
.cal_popup__val span {
  color: #999;
  font-weight: 500;
  display: block;
  margin-bottom: 0.5rem;
}
.cal_popup__val .edit-button {
  position: absolute;
  right: 0;
}
.cal_popup__val .edit-button img {
  width: 1.4rem;
  height: 1.4rem;
}
.cal_popup__val .edit {
  display: none;
}
.cal_popup__val #time_from {
  width: 3rem;
}
.cal_popup__val #time_to {
  width: 3rem;
}
.cal_popup .cal_popup__link-block {
  display: flex;
  width: 180px;
  background: rgba(103, 138, 255, 0.1);
  border-radius: 6px;
  padding: 5px 5px 5px 10px;
  margin-bottom: 1rem;
  color: #678aff;
  font-size: 12px;
  position: relative;
}
.cal_popup .cal_popup__link-block svg {
  position: absolute;
  width: 20px;
  right: 7px;
  top: 0;
}
.cal_popup .cal_popup__info {
  display: flex;
  width: 180px;
  padding: 10px 10px 10px 30px;
  background: rgba(196, 196, 196, 0.13);
  border-radius: 8px;
  position: relative;
  font-size: 12px;
  margin-bottom: 0.5rem;
}
.cal_popup .cal_popup__info .info__icon {
  position: absolute;
  left: 10px;
  top: 20px;
  width: 12px;
  margin-right: 8px;
}
.cal_popup .editing .edit {
  display: block;
}
.cal_popup .editing .view {
  display: none;
}
.cal_popup__vals {
  margin-bottom: 1.5rem;
}
.cal_popup__vals label {
  display: block;
}
.cal_popup__vals label + label {
  margin-top: 0.5rem;
}
.cal_popup__vals input {
  apperance: radio;
  -webkit-appearance: radio;
  margin-right: 0.5rem;
}
.calendar-legend {
  display: flex;
}
@media screen and (max-width: 1279.9px) {
  .calendar-legend {
    display: none;
  }
}
.fc-event-resizing {
  border: 2px solid #678aff !important;
  background: #f1f1f5 !important;
}
.fc-timegrid-event-harness {
  min-height: 3rem;
}
.fc-event-past .fc-event-lesson .fc-event-group {
  color: #f00;
}
.fc-event-past.fc-event-onelesson:before {
  background: #c5f4e1;
}
.fc-event-past.fc-event-onelesson .fc-event-main .fc-content {
  color: rgba(0, 0, 0, 0.4);
}
.fc-event-past.fc-event-onelesson .fc-content {
  color: rgba(0, 0, 0, 0.4);
}
.fc-content-wrapper {
  position: relative;
}
.fc-event-private-lesson {
  cursor: default;
}
.fc-event-private-lesson .fc-content-wrapper {
  display: none;
}
.fc-content-wrapper-legend {
  position: absolute;
  width: 4px;
  height: 84%;
  background-color: #cfacfc;
  left: -9px;
  border-radius: 12px;
  top: 2px;
}
.fc-event-onelesson .fc-event-main .fc-content {
  padding: 0.2rem 1rem 0.5rem 1.3rem;
}
.fc-event-onelesson .fc-event-main .fc-content .fc-content-wrapper .fc-content-wrapper-legend {
  background-color: #3dd598;
}
.fc-event-past.fc-event-group .fc-event-main .fc-content {
  color: rgba(0, 0, 0, 0.4);
}
.fc-event-past.fc-event-group .fc-content {
  color: rgba(0, 0, 0, 0.4);
}
@media screen and (min-width: 1280px) {
  .fc-timegrid-body {
    width: 100% !important;
  }
  .fc-timegrid-body table {
    width: 100% !important;
  }
  .fc-col-header {
    width: 100% !important;
  }
}

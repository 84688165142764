.link,
.lessons__all a {
  color: #ed3434;
  box-shadow: inset 0 -0.08em currentColor;
}
@media screen and (min-width: 1280px) {
  .link:hover,
  .lessons__all a:hover {
    box-shadow: none;
  }
}
.lessons__list {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1279.9px) {
  .lessons__list {
    margin-bottom: 2rem;
  }
}
.lessons__all {
  text-align: center;
}
@media screen and (max-width: 1279.9px) {
  .lessons__all {
    position: relative;
    margin-bottom: -2rem;
    margin-top: 3rem;
  }
}
.lesson_item {
  display: flex;
  padding: 1rem 0;
  border-radius: 1rem;
  cursor: pointer;
  position: relative;
  margin-bottom: 3rem;
}
.lesson_item--unhover {
  cursor: default;
}
.lesson_item:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #ccc;
  position: absolute;
  bottom: -1.5rem;
}
.lesson_item:last-child {
  margin-bottom: 0;
}
.lesson_item:last-child:after {
  display: none;
}
.lesson_item_status {
  width: 5.3rem;
  height: 5.3rem;
  display: flex;
  padding: 0 1.2rem;
  background: #f4f4f5;
  border-radius: 1.2rem;
  position: absolute;
  color: #92929d;
  top: 1rem;
  right: 0;
  align-items: center;
}
.lesson_item_status--done {
  background: #eff3ff;
  color: #678aff;
}
.lesson_item_status__icon {
  height: 2.9rem;
}
.lesson_item_status__icon svg {
  width: 2.9rem;
  height: 2.9rem;
  display: inline-block;
}
.lesson_item_status__text {
  margin-left: 0.7rem;
  font-size: 1.4rem;
  line-height: 1.3em;
  font-weight: bold;
  display: none;
}
.lesson_item__name {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #000;
  font-weight: 500;
  margin-bottom: 1.5rem;
}
.lesson_item__image {
  flex: 0 0 4.5rem;
  margin-right: 1.5rem;
  background: #ffc849;
  border-radius: 50%;
  width: 4.5rem;
  height: 4.5rem;
  vertical-align: middle;
  text-align: center;
  font-size: 2rem;
  text-transform: uppercase;
  line-height: 4.5rem;
  color: #fff;
}
.lesson_item__image img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
}
.lesson_item__content {
  flex: 1 0 auto;
}
.lesson_item__checkout {
  width: 100%;
}
.lesson_item__info {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.lesson_item__info:last-child {
  margin-bottom: 0;
}
.lesson_item__info_icon {
  margin-right: 0.6rem;
  height: 2rem;
}
.lesson_item__info_icon svg {
  width: 2rem;
  height: 2rem;
  display: inline-block;
}
.lesson_item__info_name {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #44444f;
  font-weight: 500;
}
.lesson_item__info_name span:before {
  width: 0.5rem;
  height: 0.5rem;
  background: #92929d;
  border-radius: 50%;
  display: inline-block;
  content: ' ';
  vertical-align: middle;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 0.2rem;
}
.lesson_item--list {
  background: #fff;
  flex: 0 0 calc(50% - 1rem);
  height: 17rem;
  padding: 2rem;
}
@media screen and (max-width: 1279.9px) {
  .lesson_item--list {
    flex: 0 0 100%;
  }
}
.lesson_item--list:hover {
  background: #fff;
}
.lesson_item--list .lesson_item_status {
  width: 17.6rem;
  top: auto;
  bottom: 2rem;
  right: 2rem;
}
@media screen and (max-width: 1279.9px) {
  .lesson_item--list .lesson_item_status {
    width: 5.6rem;
  }
}
.lesson_item--list .lesson_item_status__text {
  display: block;
}
@media screen and (max-width: 1279.9px) {
  .lesson_item--list .lesson_item_status__text {
    display: none;
  }
}
.lesson_item--list .lesson_item__image {
  flex: 0 0 9.5rem;
  margin-right: 1.5rem;
  height: 9.5rem;
  line-height: 9.5rem;
  font-size: 3rem;
}
@media screen and (max-width: 1279.9px) {
  .lesson_item--list .lesson_item__image {
    width: 4.5rem;
    height: 4.5rem;
    line-height: 4.5rem;
    font-size: 2rem;
    flex: 0 0 4.5rem;
  }
}
.lesson_item--list .lesson_item__image img {
  width: 9.5rem;
  height: 9.5rem;
}
@media screen and (max-width: 1279.9px) {
  .lesson_item--list .lesson_item__image img {
    width: 4.5rem;
    height: 4.5rem;
  }
}

.requests2 {
  margin-bottom: 2rem;
}
.requests2__header {
  background: $yellowColor;
  height: 5.6rem;
  border-radius: 2rem 2rem 0 0;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  color: #000;
  justify-content: space-between;
}
@media screen and (max-width: 1279.9px) {
  .requests2__header {
    padding: 0 2rem;
    font-size: 1.8rem;
  }
}
.requests2__content {
  display: flex;
  background: #fff;
  padding: 2rem 3rem 1rem;
  border-radius: 0 0 2rem 2rem;
  flex-wrap: wrap;
  max-height: 17rem;
  overflow: auto;
}
@media screen and (max-width: 1279.9px) {
  .requests2__content {
    padding: 1.5rem 2rem;
  }
}
.requests2_item {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  flex: 0 0 calc(50% - 3rem);
  margin-right: 3rem;
  align-items: center;
}
@media screen and (max-width: 1279.9px) {
  .requests2_item {
    flex-wrap: wrap;
    width: 100%;
    flex: 0 0 100%;
  }
}
.requests2_item__profile {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
}
@media screen and (max-width: 1279.9px) {
  .requests2_item__profile {
    flex: 1 1 100%;
    margin-bottom: 1.5rem;
  }
}
.requests2_item__timer {
  width: 12rem;
  height: 3.8rem;
  border: 1px solid #678aff;
  background: rgba(103, 138, 255, 0.6);
  border-radius: 1rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  flex: 0 0 12rem;
  position: relative;
  overflow: hidden;
}
.requests2_item__timer div {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #678aff;
  transition: all 333ms linear;
}
.requests2_item__timer span {
  z-index: 2;
  position: relative;
}
.requests2_item__image {
  margin-right: 2rem;
  font-size: 0;
  width: 5rem;
  background: $yellowColor;
  border-radius: 50%;
  height: 5rem;
  vertical-align: middle;
  text-align: center;
  font-size: 2rem;
  text-transform: uppercase;
  line-height: 5rem;
  color: #fff;
}
.requests2_item__image img {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}
.requests2_item__name {
  font-size: 1.6rem;
  font-weight: 500;
}
.requests2_item__actions {
  flex: 0 0 3.8rem;
  height: 3.8rem;
}
.requests2_item__actions .action {
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 1rem;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}
.requests2_item__actions .action-reload {
  background: rgba(103, 138, 255, 0.1);
}
.requests2_item__actions .action-cancel {
  color: #ed3434;
  background: rgba(237, 52, 52, 0.1);
}
.requests2_item__actions .action svg {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
}

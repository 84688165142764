:root {
  --headerHeight: 64px;
  --footerHeight: 40px;
  --breadcrumbsHeight: 44px;
  --asideNavBarWidth: 244px;
  --asideNavBarWidthCollapsed: 60px;
  --mainBlockHeight: calc(100vh - var(--headerHeight) - var(--footerHeight));
  --mainBlockMinHeightDesktop: 600px;
  --groupLessonSubjectHeightDesktop: calc(24px + 44px);

  --groupLessonHeaderOwnerHeightMobile: 90px;
  --groupLessonHeaderMemberHeightMobile: 45px;
  --groupLessonToolbarHeightMobile: 60px;
  --headerHeightMobile: 64px;
  --footerHeightMobile: 150px;
  --breadcrumbsHeightMobile: calc(1.5rem + 20px + 1.5rem);
  --mainBlockHeightMobile: calc(100*var(--vh) - var(--headerHeightMobile) - var(--footerHeightMobile));
  --mainBlockHeightMobileWithoutFooterPage: calc(100*var(--vh) - var(--headerHeight));
  --mainBlockPadding: 30px;
  --mainBlockPaddingMobile: 16px;
  --containerPaddingMobile: 16px;

  --ezteach-group-lesson-next-header: 48px;
  --ezteach-group-lesson-next-toolbar: 84px;

  --group-lesson-chat-footer-height: 50px;

  @media screen and (max-width: 1279.9px) {
    --ezteach-group-lesson-next-header: 40px;
    --ezteach-group-lesson-next-toolbar: 72px;
  }

  @media screen and (max-width: 369.9px) {
    --ezteach-group-lesson-next-toolbar: 64px;
  }

}
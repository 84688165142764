@import './app/_styles/normalize.scss';
@import './app/_styles/GUI.scss';
@import './app/_styles/common.scss';
@import './app/_styles/btn.scss';
@import './app/_styles/input.scss';
@import './app/_styles/nav.scss';
@import './app/_styles/material.scss';
@import './app/_styles/fullcalendar.scss';
@import './app/_styles/tippy.scss';
@import './app/_styles/toast.scss';

@import './app/_components/header/header.component.scss';
@import './app/_components/lesson_item/lesson_item.component.global.scss';
@import './app/_components/tutor-user-search-result/tutor-user-search-result.component.scss';
@import './app/forms/search.scss';
@import './app/online/online.component.scss';
@import './app/requests-tutor/requests-tutor.component.scss';
@import './app/requests-student/requests-student.component.scss';
@import './app/teacher/teacher.component.scss';
@import './app/lessons/lessons.component.scss';
@import './app/messages/messages.component.scss';
@import './app/settings/settings.component.scss';
@import './app/calendar/components/calendar/calendar.component.scss';
@import './app/modals/modals.component.scss';
@import './app/bookmarks/bookmarks.component.scss';
@import './app/profile/profile.component.scss';
@import 'app/profile-old/profile.component.scss';
@import './app/widgets/widgets.scss';
@import './app/wizard/wizard.component.scss';
@import './app/search-regular/search-regular.components.scss';
@import './app/_components/custom-snackbar/custom-snackbar.scss';
@import './app/_styles/create-meeting-popup.scss';

.easy-faq .markdown h3 {
  font-weight: bold !important;
}

.easy-faq .markdown h4 {
  font-weight: bold !important;
}

.easy-faq .markdown h5 {
  font-size: 14px;
  font-weight: bold !important;
}

.easy-faq img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 18px;
  margin-bottom: 18px;
}

.easy-faq a {
  color: #678aff;
}

.easy-faq li {
  list-style-type: decimal;
  margin-left: 24px;
}
.wizard {
  background: #fff;
  border-radius: 2rem;
}
@media screen and (max-width: 1279.9px) {
  .wizard .btn {
    width: 100%;
  }
  .wizard .btn__label {
    width: 100%;
    text-align: center;
  }
}
.wizard hr {
  border: 0;
  background: #e5e5e5;
  height: 1px;
  font-size: 0;
  margin: 1.5rem 0;
}
.wizard .file {
  border: 1px solid #eceef5;
  justify-content: space-between;
  height: 5.6rem;
  display: flex;
  border-radius: 1rem;
  background: #fff;
  align-items: center;
  text-align: left;
  width: 29rem;
}
@media screen and (max-width: 1279.9px) {
  .wizard .file {
    width: 22rem;
  }
}
.wizard .file__wrap {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;
}
.wizard .file__delete {
  margin-left: 1.5rem;
}
.wizard .file__delete a {
  color: #ed3434;
  font-size: 1.4rem;
  font-weight: 500;
}
.wizard .file + .file {
  margin-top: 2rem;
}
.wizard .file__cloud {
  margin-right: 1rem;
}
.wizard .file__cloud img {
  width: 3.6rem;
  height: 3.6rem;
}
.wizard .file__icon {
  background: #eff3ff;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wizard .file__icon img {
  max-width: 2rem;
  max-height: 2rem;
}
.wizard .file__info {
  margin-right: 1rem;
  flex: 1 1 auto;
}
.wizard .file__name {
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 18rem;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}
@media screen and (max-width: 1279.9px) {
  .wizard .file__name {
    max-width: 14rem;
  }
}
.wizard .file__size {
  font-size: 1.2rem;
  color: #92929d;
  display: block;
}
.wizard_header {
  display: flex;
  padding: 3rem;
  border-bottom: 1px solid #e2e2ea;
}
@media screen and (max-width: 1279.9px) {
  .wizard_header {
    padding: 2rem;
  }
}
.wizard_header__title {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: bold;
}
.wizard_content {
  display: flex;
  padding: 2rem 3rem;
}
@media screen and (max-width: 1279.9px) {
  .wizard_content {
    padding: 1.5rem 2rem;
  }
}
@media screen and (max-width: 1279.9px) {
  .wizard_content {
    flex-wrap: wrap;
  }
}
.wizard_content__delete {
  margin-bottom: 2rem;
}
.wizard_content__delete a {
  color: #ed3434;
}
.wizard_content__left {
  flex: 0 0 30rem;
  margin-right: 3rem;
  border-right: 1px solid #e2e2ea;
  padding-right: 3rem;
}
@media screen and (max-width: 1279.9px) {
  .wizard_content__left {
    flex: 0 0 100%;
    padding-right: 0;
    border-right: 0;
    margin-right: 0;
  }
}
.wizard_content__right {
  flex: 1 1 auto;
  width: 100%;
}
@media screen and (max-width: 1279.9px) {
  .wizard_content__right {
    flex: 0 0 100%;
    border-top: 1px solid #e2e2ea;
    margin-top: 2rem;
    padding-top: 2rem;
  }
}
.wizard_nav__item {
  margin-bottom: 1rem;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 500;
}
.wizard_nav__item a {
  color: #44444f;
}
.wizard_nav__item a.active {
  color: #678aff;
  border-bottom: 2px solid #678aff;
}
.wizard_nav__item span {
  color: rgba(0,0,0,0.3);
}
.wizard_nav__item + .wizard_nav__item {
  margin-top: 2rem;
}

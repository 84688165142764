.online {
  display: block;
  height: 24rem;
  display: flex;
  border-radius: 2rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 1279.9px) {
  .online {
    flex-wrap: wrap;
    height: auto;
  }
}
.online .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.online .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.online .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.online .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: #3dd598;
}
.online .mat-checkbox-frame {
  border-width: 1px;
}
.online__left {
  width: 43rem;
  background: #ffc849;
  border-radius: 2rem 0 0 2rem;
  padding: 4rem 3rem;
}
@media screen and (max-width: 1279.9px) {
  .online__left {
    border-radius: 2rem 2rem 0 0;
    padding: 2rem 2rem;
  }
}
.online__right {
  background: #fff;
  flex: 1 1 auto;
  border-radius: 0 2rem 2rem 0;
  display: flex;
  align-items: center;
  padding: 0 3rem;
}
@media screen and (max-width: 1279.9px) {
  .online__right {
    padding: 2rem;
    flex-wrap: wrap;
    border-radius: 0 0 2rem 2rem;
  }
}
.online__checker {
  border-radius: 1rem;
  box-shadow: 0 0 1rem rgba(155, 155, 155, 0.5);
  background: #fff;
  width: 17.5rem;
  height: 16rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 4rem;
}
@media screen and (max-width: 1279.9px) {
  .online__checker {
    margin-right: 0;
    height: 10rem;
    width: 100%;
    margin-bottom: 2rem;
  }
}
.online__checker_title {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 2rem;
}
.online__checker_title.on {
  display: none;
}
.online__checker .mat-slide-toggle-bar {
  width: 6rem;
}
.online__checker .mat-slide-toggle-thumb-container {
  width: 2.5rem;
  height: 2.5rem;
  top: -6px;
}
.online__checker .mat-slide-toggle-thumb {
  width: 2.5rem;
  height: 2.5rem;
}
.online__checker .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background: rgba(103, 138, 255, 0.5);
}
.online__checker .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb,
.online__checker .mat-slide-toggle.mat-checked .mat-ripple-element {
  background: #678aff;
}
.online__checker .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(3.5rem, 0, 0);
}
.online__checker.isOnline {
  box-shadow: 0 0 1rem rgba(103, 138, 255, 0.5);
}
.online__checker.isOnline .off {
  display: none;
}
.online__checker.isOnline .on {
  display: block;
}
.online__title {
  font-size: 3.2rem;
  font-weight: bold;
  color: #44444f;
  line-height: 3.8rem;
  margin-bottom: 1.2rem;
}
.online__line {
  color: #92929d;
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
}
.online__line .mat-select {
  width: 10rem;
}
.online__line .timer {
  color: #678aff;
  font-size: 2.8rem;
  font-weight: 500;
  margin-right: 2rem;
}
.online__line_title {
  margin-bottom: 0.5rem;
}
.online__line .mat-checkbox {
  margin-right: 2rem;
}
.online__text {
  font-size: 1.6rem;
  font-weight: 500;
  color: #44444f;
  line-height: 2.4rem;
}

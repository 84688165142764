.bookmarks_header {
  background: #fff;
  border-radius: 2rem;
  position: relative;
  height: 7.8rem;
  display: flex;
  align-items: center;
  padding: 0 3rem;
  margin-bottom: 2rem;
}
.bookmarks_header__name {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: bold;
}
.bookmarks_header__icon {
  width: 6.2rem;
  height: 6.2rem;
  position: absolute;
  right: 7rem;
  top: 0;
}

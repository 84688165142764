.settings {
  border-radius: 2rem;
  background: #fff;
}
.settings .mat-slide-toggle-bar {
  height: 0.8rem;
}
.settings .mat-slide-toggle-thumb-container {
  width: 1.6rem;
  height: 1.6rem;
  top: -0.5rem;
}
.settings .mat-slide-toggle-bar {
  width: 3.2rem;
}
.settings .mat-slide-toggle-thumb {
  width: 1.6rem;
  height: 1.6rem;
}
.settings .mat-slide-toggle-content {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}
.settings .mat-slide-toggle.mat-checked .mat-ripple-element,
.settings .mat-slide-toggle.mat-checked .mat-slide-toggle-bar,
.settings .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #678aff;
}
.settings .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.settings .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.settings .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.settings .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #3dd598;
}
.settings_header {
  padding: 3rem;
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: bold;
  border-bottom: 1px solid #e2e2ea;
}
@media screen and (max-width: 1279.9px) {
  .settings_header {
    padding: 2rem;
  }
}
.settings_cols {
  display: flex;
}
.settings_cols__col {
  flex: 0 0 50%;
}
.settings_cols__title {
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 3rem;
}
.settings_cols__item + .settings_cols__item {
  margin-top: 1.5rem;
}
.settings_cols__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
}
.settings_cols__item--image {
  max-width: 20rem;
}
.settings_cols__item img {
  flex: 0 0 4rem;
  max-height: 3.5rem;
  max-width: 3.5rem;
  margin-left: 2rem;
}
.settings_cols + .settings_cols {
  margin-top: 5rem;
}
.settings_block {
  border-bottom: 1px solid #e2e2ea;
  padding: 2rem 3rem;
  display: flex;
}
@media screen and (max-width: 1279.9px) {
  .settings_block {
    flex-direction: column;
    padding: 2rem;
  }
}
.settings_block__title {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
  margin-bottom: 3rem;
}
.settings_block__text {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #696974;
  max-width: 34rem;
}
.settings_block__left {
  flex: 0 0 42rem;
  padding-right: 3rem;
}
@media screen and (max-width: 1279.9px) {
  .settings_block__left {
    flex: 0 0 100%;
  }
}
.settings_block__right {
  flex: 1 0 auto;
}
.settings_actions {
  text-align: right;
  padding: 3rem;
}

.profile {
  background: #fff;
  border-radius: 2rem;
}

.profile .mat-form-field-underline {
  display: block;
}

.profile .mat-input-element {
  text-align: left !important;
}

.profile__row {
  display: flex;
  justify-content: space-between;
}

.profile__row .mat-form-field {
  flex: 1 1 auto;
  margin-right: 2rem;
}

.phone-input-container {
  display: flex;
  align-items: center;

  .phone-prefix {
    width: 10px;
    border: none;
    background: transparent;
    margin-top: -0.0625em;
  }

  .phone-number {
    flex: 1;
  }
}

@media screen and (max-width: 1279.9px) {
  .profile__row {
    display: block;
  }
}

.profile .group {
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.profile .group .profile_content__delete {
  margin-bottom: 0;
}

@media screen and (max-width: 1279.9px) {
  .profile .btn {
    width: 100%;
  }

  .profile .btn__label {
    width: 100%;
    text-align: center;
  }
}

.profile hr {
  border: 0;
  background: #e5e5e5;
  height: 1px;
  font-size: 0;
  margin: 1.5rem 0;
}

.profile_specs,
.profile .discipline {
  margin-bottom: 2rem;
}

.profile_specs__title,
.profile .discipline__title {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.profile_specs__list,
.profile .discipline__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}

.profile_specs__list label,
.profile .discipline__list label {
  flex: 0 0 30%;
  padding-right: 3rem;
}

@media screen and (max-width: 1279.9px) {

  .profile_specs__list label,
  .profile .discipline__list label {
    flex: 0 0 50%;
    padding-right: 1rem;
  }
}

.profile_specs__delete,
.profile .discipline__delete {
  font-size: 1.4rem;
  font-weight: 500;
}

.profile_specs__delete a,
.profile .discipline__delete a {
  color: #ed3434;
}

.profile input[type='checkbox'] {
  apperance: checkbox;
  -webkit-appearance: checkbox;
  margin-right: 0.5rem;
}

.profile .file {
  border: 1px solid #eceef5;
  justify-content: space-between;
  height: 5.6rem;
  display: flex;
  border-radius: 1rem;
  background: #fff;
  align-items: center;
  text-align: left;
  width: 29rem;
}

.profile .file__wrap {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;
}

.profile .file__delete {
  margin-left: 1.5rem;
}

.profile .file__delete a {
  color: #ed3434;
  font-size: 1.4rem;
  font-weight: 500;
}

.profile .file+.file {
  margin-top: 2rem;
}

.profile .file__cloud {
  margin-right: 1rem;
}

.profile .file__cloud img {
  width: 3.6rem;
  height: 3.6rem;
}

.profile .file__icon {
  background: #eff3ff;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile .file__icon img {
  max-width: 2rem;
  max-height: 2rem;
}

.profile .file__info {
  margin-right: 1rem;
  flex: 1 1 auto;
}

.profile .file__name {
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 18rem;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

@media screen and (max-width: 1279.9px) {
  .profile .file__name {
    max-width: 11rem;
  }
}

.profile .file__size {
  font-size: 1.2rem;
  color: #92929d;
  display: block;
}

.profile_header {
  display: flex;
  padding: 3rem;
  border-bottom: 1px solid #e2e2ea;
}

@media screen and (max-width: 1279.9px) {
  .profile_header {
    padding: 2rem;
  }
}

.profile_header__title {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: bold;
}

.profile_header__left {
  flex: 0 0 30rem;
  margin-right: 3rem;
}

@media screen and (max-width: 1279.9px) {
  .profile_header__left {
    flex: 0 0 100%;
    margin-right: 0;
  }
}

@media screen and (max-width: 1279.9px) {
  .profile_header__right {
    display: none;
  }
}

.profile_name {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
  margin-bottom: 0.8rem;
}

.profile_cardlist {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.profile_card {
  flex: 0 0 31.2rem;
  margin-right: 4rem;
  margin-bottom: 4rem;
  font-size: 1.3rem;
  font-weight: 500;
  position: relative;
}

@media screen and (max-width: 1279.9px) {
  .profile_card {
    flex: 0 0 100%;
    margin-right: 0;
    width: 100%;
    margin-bottom: 3rem;
  }
}

.profile_card__delete {
  color: #ed3434;
  font-size: 1.4rem;
  position: absolute;
  right: 0;
  bottom: 0.1rem;
}

.profile_card__name {
  font-size: 1.8rem;
  flex: 1 0 auto;
}

.profile_card__wrap {
  width: 31.2rem;
  height: 18.4rem;
  background: url('/assets/img/content/bg_card.svg') no-repeat;
  color: #fff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  justify-content: space-between;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
}

@media screen and (max-width: 1279.9px) {
  .profile_card__wrap {
    background-size: cover;
    width: 100%;
    border-radius: 2rem;
    overflow: hidden;
  }
}

.hideinput {
  position: absolute;
  left: -9999px;
  top: -9999px;
}

.reference_list {
  display: flex;
  flex-wrap: wrap;
}

.reference_item {
  flex: 0 0 auto;
  max-width: 33rem;
  border-radius: 1.5rem;
  overflow: hidden;
  border: 1px solid #eceef5;
  margin-bottom: 3rem;
  margin-right: 3rem;
}

@media screen and (max-width: 1279.9px) {
  .reference_item {
    max-width: 100%;
  }
}

.reference_item__name {
  max-width: 23rem;
  word-break: break-all;
}

@media screen and (max-width: 1279.9px) {
  .reference_item__name {
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 18rem;
    white-space: nowrap;
    display: block;
    overflow: hidden;
  }
}

.reference_item__image {
  width: 100%;
  height: 15rem;
}

.reference_item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reference_item__content {
  width: 33rem;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1279.9px) {
  .reference_item__content {
    width: 100%;
  }
}

.reference_item__delete a {
  color: #ed3434;
}

.profile_content {
  display: flex;
  padding: 2rem 3rem;
}

@media screen and (max-width: 1279.9px) {
  .profile_content {
    padding: 1.5rem 2rem;
  }
}

@media screen and (max-width: 1279.9px) {
  .profile_content {
    flex-wrap: wrap;
  }
}

.profile_content__delete {
  margin-bottom: 2rem;
}

.profile_content__delete a {
  color: #ed3434;
}

.profile_content__left {
  flex: 0 0 30rem;
  margin-right: 3rem;
  border-right: 1px solid #e2e2ea;
  padding-right: 3rem;
}

@media screen and (max-width: 1279.9px) {
  .profile_content__left {
    flex: 0 0 100%;
    padding-right: 0;
    border-right: 0;
    margin-right: 0;
  }
}



.profile_content__right {
  flex: 1 1 auto;
  width: 100%;
}

@media screen and (max-width: 1279.9px) {
  .profile_content__right {
    flex: 0 0 100%;
    border-top: 1px solid #e2e2ea;
    margin-top: 2rem;
    padding-top: 2rem;
  }
}

.profile_content__action {
  margin-top: 3rem;
}

@media screen and (max-width: 1279.9px) {
  .profile_content__action {
    margin-top: 2rem;
    border-top: 1px solid #e2e2ea;
    padding-top: 2rem;
  }

  .profile_content__action .btn {
    width: 100%;
  }

  .profile_content__action .btn__label {
    text-align: center;
    width: 100%;
  }
}

.profile_content__title {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.profile_content__subtitle {
  font-size: 1.4rem;
  color: #92929d;
  margin-bottom: 1.5rem;
}

.profile_content__input {
  width: 100%;
}

.profile_content__cols {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1279.9px) {
  .profile_content__cols {
    flex-wrap: wrap;
  }
}

.profile_content__cols+.profile_content__cols {
  margin-top: 2rem;
}

@media screen and (max-width: 1279.9px) {
  .profile_content__cols+.profile_content__cols {
    margin-top: 0;
  }
}

.profile_content__col {
  flex: 0 0 calc(100% / 2 - 1.5rem);
}

@media screen and (max-width: 1279.9px) {
  .profile_content__col {
    flex: 0 0 100%;
  }
}

.profile_image {
  width: 14rem;
  height: 14rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffc849;
  margin: 0 auto 3.5rem;
  position: relative;
  .avatar-image{
    border-radius: 50%;
    width:100%;
    height: 100%;
    cursor: pointer;
  }
}

.avatar-tooltop {
  background-color: white;
  box-sizing: border-box;
  padding:15px;
  width:400px;
  height: 450px;
  border-radius: 5px;
  .avatar-tooltop__croparea{
    width:370px;    
    height: 370px;
    position: relative;
    overflow: hidden;

    .avatar-tooltop__image {
      border-radius: 0;
      max-width: none;
    }
    .avatar-tooltop__viewPort{
      position: absolute;
      top:0;
      left:0;
      border-radius: 50%;
      bottom: 0;
      cursor: pointer;
      right: 0;
      box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, 0.5);
    }
    
  }
  
  &.sl-cpor-image-wrapper {
    position: relative; }
  .avatar-tooltop__scale{
      display: flex;
      justify-content: space-between;
      height: 50px;
      box-sizing: border-box;
      padding: 10px 30px;
      .btn{
        padding: 0 20px;
      }
    }
  .sl-cpor-image-area {
    position: absolute;
    left: 50%;
    top: 50%;
    border: 2px dashed black;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    color: transparent;
    min-width: 100px;
    min-height: 100px;
    cursor: move; }
  
  .sl-resize-crop {
    position: absolute;
    right: -2px;
    bottom: -2px;
    width: 4px;
    height: 4px;
    background-color: #FFF;
    color: transparent;
    cursor: se-resize; }
}

.profile_image__letters {
  font-size: 5rem;
  color: #fff;
}

.profile_image .button-avatar {
  position: absolute;
  width: 4.3rem;
  height: 4.3rem;
  border-radius: 50%;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.14);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: 0.5rem;
  bottom: 0.5rem;
}

.profile_image .button-avatar svg {
  width: 2.4rem;
  height: 2.3rem;
}

.profile_image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profile_image #avatar {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.profile_subscribe {
  border-top: 1px solid #e2e2ea;
  padding-top: 30px;
  display: flex;
}

.profile_nav {
  border-top: 1px solid #e2e2ea;
  margin-top: 3rem;
  padding-top: 3rem;
}

.profile_nav__item {
  margin-bottom: 1rem;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 500;
}

.profile_nav__item a {
  color: #44444f;
}

.profile_nav__item a.active {
  color: #678aff;
  border-bottom: 2px solid #678aff;
}

.profile_nav__item+.profile_nav__item {
  margin-top: 2rem;
}

.uId {
  color: #696974;
  cursor: auto;
}
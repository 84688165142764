.lessons_item {
  display: flex;
  background: #fff;
  border-radius: 2rem;
  justify-content: space-between;
  overflow: hidden;
  flex-wrap: wrap;
  min-height: 60rem;
  height: calc(var(--mainBlockHeight) - 6rem);
}
@media screen and (max-width: 1279.9px) {
  .lessons_item {
    min-height: 50rem;
  }
}
.lessons_item__left {
  flex: 0 1 100%;
  background: #151d3a;
  border-radius: 2rem;
  positon: relative;
  height: 100%;
}
.lessons_item__right {
  flex: 0 0 40rem;
  max-width: 40rem;
  display: none;
  border-radius: 0 2rem 2rem 0;
  padding-bottom: 2rem;
  height: 100%;
}
.lessons_item__right .messages__top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 6rem;
}
.lessons_item.hold .lessons_item__left {
  flex: 0 1 100%;
}
.lessons_item.hold .lessons_item__right {
  display: none;
}
.lessons_item.hold .lesson__video,
.lessons_item.hold .lesson__video_out {
  display: none;
}
.lessons_item__info {
  flex: 0 0 5.5rem;
}
.lessons_item__footer {
  flex: 0 0 100%;
  padding: 2rem 0;
  text-align: center;
  background: #eff3ff;
}
.lessons_item_file {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.lessons_item_file__name {
  font-size: 1.6rem;
  color: #678aff;
}
.lessons_item_file__icon {
  height: 3.6rem;
  margin-left: 1rem;
}
.lessons_item.showMessages .lessons_item__right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 3rem;
}
.lessons_item.showMessages .lessons_item__right .messages__list {
  flex: 0 1 auto;
  margin-bottom: 2rem;
  height: 100%;
  overflow: scroll;
}
.lessons_item.showMessages .lessons_item__left {
  border-radius: 2rem 0 0 2rem;
  flex: 0 1 calc(100% - 40rem);
}
@media screen and (max-width: 1279.9px) {
  .lessons_item.showMessages .lessons_item__left {
    flex: 0 1 100%;
  }
}
.lessons_item.hasVideo .lessons_item__left,
.lessons_item.hasVideo .lessons_item__right {
  height: calc(100% - 8rem);
}
.lessons_item.static {
  transform: translate3d(0, 0, 0) !important;
}
@media screen and (max-width: 1279.9px) {
  .lessons_item.static.showMessages {
    height: 100rem;
  }
  .lessons_item.static.showMessages .lessons_item__left {
    height: 50%;
  }
  .lessons_item.static.showMessages .messages__top {
    display: none;
  }
  .lessons_item.static.showMessages .lessons_item__right {
    height: 50%;
    flex: 0 0 100%;
    padding-right: 0;
    width: 100%;
  }
  .lessons_item.static.showMessages .messages_input {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.lessons_item.minimize {
  display: none;
}
.lessons_item.minimize .lesson__action--pip {
  width: 4rem;
  height: 4rem;
  position: absolute;
  z-index: 5;
  right: 0;
  bottom: 1rem;
  opacity: 0;
}
.lessons_item.minimize .lesson__action--pip svg {
  width: 2rem;
  height: 2rem;
}
.lessons_item.minimize:hover .lesson__action--pip {
  opacity: 1;
}
.lessons_item.minimize.started {
  display: block;
  position: fixed;
  z-index: 5;
  right: 3rem;
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid #fff;
  width: 24rem;
  height: 18rem;
  background: #000;
  min-height: 12rem;
}
.lessons_item.minimize.started .lessons_item__left {
  flex: 0 0 100%;
}
.lessons_item.minimize.started .lesson__right,
.lessons_item.minimize.started .lesson_info,
.lessons_item.minimize.started .lesson__date,
.lessons_item.minimize.started .lesson__name,
.lessons_item.minimize.started .lesson__text,
.lessons_item.minimize.started .lesson__actions,
.lessons_item.minimize.started .lesson_text {
  display: none;
}
.lessons_item.minimize.started .lesson__video_out {
  opacity: 0;
}
@media screen and (max-width: 1279.9px) {
  .lessons_item.is-history .lessons_item__right {
    display: none;
  }
}

search-regular,
:host {
  display: block;
}
search-regular{
  
    background: #fff;
    border-radius: 2rem;
    box-sizing: border-box;
    padding: 3rem;
    @media screen and (max-width: 900px) {
      padding: 0.8rem;
    }
    .search-regular__header{
      display: flex;
      justify-content: space-between;
    
    .search-regular__bread-crumbs{
      color: #92929D;
      line-height: 1.6rem;
      font-size: 1.4rem;
      font-weight: 400;
      .crumb{
        display: inline-block;
        margin-right: 2.4rem;
        &::after{
          content: ">";
          display: inline-block;
          color: #92929D;
          margin-left: 2.4rem;
        }
        &:last-child{
          &::after{
            content: none;
          }
        }
        &.home{
          background-size: 1.6rem;
          height: 1.6rem;
          width: 1.6rem;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('../../assets/img/svg/icon-home.svg');
        }
      }
      
      margin-top: 1.2rem;
      margin-bottom: 4.2rem;
      @media screen and (max-width: 900px) {
        margin-bottom: 0.8rem;
      }
    }
    .search-regular__settings{
      background-size: 17px 17px;
      background-image: url('../../assets/img/svg/icon-view-settings.svg');
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background-color: white;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 0px 0px 10px 0px #00000024;
      cursor: pointer;
      @media screen and (max-width: 900px) {
        display: none;
      }
    }
    
  }
    .search-regular__title {
      font-weight: 700;
      color: #44444F;
      font-size: 3.2rem;
      line-height: 3.7rem;
      margin-bottom: 3rem;
      @media screen and (max-width: 900px) {
        font-size: 2rem;
        line-height: 2.2rem;
        margin-bottom: 1.2rem;
      }
    }
    .search-regular__filters-form{
      .search-regular__filter {
        box-sizing: border-box;
        display: inline-block;
        vertical-align: top;
        border-radius: 0.6rem;
        margin-bottom: 3rem;
        .mat-form-field-wrapper {
          padding:0;
          margin:0;
        }
        .mat-form-field-label-wrapper{
          display: none;
        }
        .mat-form-field-infix{
          border-top:0 !important;
        }
        .mat-form-field-outline-end, .mat-form-field-outline-start {
          border-color: transparent !important;
        }
        .mat-form-field-suffix{
            top: -5px
        } 
        .option, .name, .input, &.select, &.datetime, &.time {
          display: inline-block;
          vertical-align: middle;
          color: #44444F;
          border: 1px solid #92929D99;
            font-weight: 500;
            line-height: 1.6rem;
            font-size: 1.6rem;
          box-sizing: border-box;
          padding: 1.1rem 1.2rem;
          
          
          
          &:first-child{
            border-radius:   0.6rem 0 0 0.6rem;
            border-left: 1px solid #92929D99;
          }
          &:last-child{            
            border-radius: 0 0.6rem 0.6rem 0 ;
          }
          &.select, &.datetime{
            padding: 0 0 0 1.2rem;
            border-radius: 0.6rem;
            height: 4rem;            
          }
          &.datetime{
            box-sizing: border-box;
            .mat-select{
              padding-top: 1rem;
              margin-right: 1rem;
            }
          }
          &.select{
            position: relative;
            &::after{
              content: '';
              position: absolute;
              right: 1.5rem;
              top: 1.5rem;
              width: 0;
              height: 0;

              border-left: 5px solid rgba(0,0,0,0);
              border-right: 5px solid rgba(0,0,0,0);
              border-top: 5px solid #92929D;
              margin: 0 4px;
            }
          }             
        }
        .option {
          border-left: 0;
          &.choosen{
            background-color: #678AFF1A;            
          }
          cursor: pointer;      
          &.selected-option{
            background-color: #678AFF1A;

          }
          &:first-child{
            border-left: 1px solid #92929D99;
          }
        }
        &.time{
          display: flex;
          .label{
            width:50%;
            margin-right: 10px;
            white-space: nowrap;
          }
          .select{
            min-width: 25%;
            text-align: right;
          }
        }
      }
      .search-regular__filters-row{
        display: flex;
        justify-content:space-between;
        
        margin-bottom: 3rem;
        @media screen and (max-width: 800px){
          display: block;
          margin-bottom: 0.4rem;
          &.time{
            width: 100%;
          }
        }

        .search-regular__filter {
          margin-bottom: 0;
          width: calc((100% - 2.60rem) / 3);
          
          margin-right: 1.9rem;
          @media screen and (max-width: 800px){
            width:calc(50% - 2rem);
            margin-bottom: 0.8rem;
          }
          @media screen and (max-width: 767px){
            width:100%;
            margin-right: 0;
          }
          &.price{
            white-space: nowrap;
            width: 24rem;
            margin-right: 0;
            .input {
              width: 9.3rem;
              overflow-x: hidden;
              input { 
                height: 1.6rem;
                width:100%;
              }
            }
          }
          
          mat-form-field{
            width: 100%;
            mat-autocomplete{
              width: 100%;
            }
          }
          &.full-width{
            width: auto;
            max-width: 100%;
            @media screen and (max-width: 800px) {
              width:100%;
              .option{
                border-radius: 0.6rem;
                border: 1px solid #678AFF;
                color: #678AFF;
                padding: 0.5rem 1.2rem;
                font-size: 1.4rem;
                margin-right: 1.2rem;
                margin-bottom: 1.2rem;
                
              }
            }
          }
        } 
        .btn{
          padding: 0 6rem;
          @media screen and (max-width: 800px){
            width: 50%;
            margin-bottom: 2rem;
          }
          @media screen and (max-width: 767px){
            width: 100%;
          }
          
        }  
        .all-filters__left {
          display: flex;
          @media screen and (max-width: 850px){
            display: block;
          }
          .all-filters,.sort,.reset-filters {
            color: #44444F;
            cursor: pointer;
            position: relative;
            margin-right: 2rem;
            height: 3.8rem;
            line-height: 3.8rem;
            box-sizing: border-box;
            padding: 0 1.2rem;
            padding-right: 4rem;
            font-size: 1.5rem;
            font-weight: 500;
            @media screen and (max-width: 850px){
              display: inline-block;
            }
          }
          .all-filters,.sort{
            &::after{
              content: '';
              position: absolute;
              right: 1.5rem;
              top: 1.5rem;
              width: 0;
              height: 0;

              border-left: 5px solid rgba(0,0,0,0);
              border-right: 5px solid rgba(0,0,0,0);
              border-top: 5px solid #678AFF;
              margin: 0 4px;
            }
            &.opened{
              &::after{
                content: '';
                position: absolute;
                right: 1.5rem;
                top: 1.5rem;
                width: 0;
                height: 0;
                
                border-left: 5px solid rgba(0,0,0,0);
                border-right: 5px solid rgba(0,0,0,0);
                border-bottom: 5px solid #678AFF;
                border-top: 0;
                margin: 0 4px;
              }
            }
          }
          .all-filters{
            color:#678AFF;
          }
          .reset-filters{
            background-image: url('../../assets/img/svg/icon-close_dark.svg');;
            background-size: 1.4rem ;
            background-repeat: no-repeat;
            background-position: right center;
          }
          .sort {
            &::after{
              border-top: 5px solid #92929D;
            }
            &.opened{
              &::after{
                border-bottom: 5px solid #92929D;
                border-top: 0;
              }              
            }
          }
        }     
      }
    }
    
}
.discipline-options{
  &:nth-child(2n) {
    background: #678AFF1A;    
  }
  &.time{
    text-align: right;
  }
}
.search-regular__menu-popup{
  background-color: #FFFFFF;
  border-radius: 0.8rem;
  box-sizing: border-box;
  padding: 1.6rem;
  box-shadow: 0px 0px 13px 0px #00000024;
  width:32rem;
  &.narrow{
    width: auto;
  }
  .title{
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
    color: #92929DD9;
  }
  .grid-view, .list-view{
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: 1.6rem 1.6rem;
    background-position: left;
    padding-left: 2.8rem;
    cursor: pointer;
  }
  .grid-view{
    background-image: url('../../assets/img/svg/icon-grid.svg');
    &:hover, &.choosen{
      background-image: url('../../assets/img/svg/icon-grid-blue.svg');
    }
  }
  .list-view{
    background-image: url('../../assets/img/svg/icon-view-settings.svg');
    &:hover, &.choosen{
      background-image: url('../../assets/img/svg/icon-view-settings-blue.svg');
    }
  }
  .sort-field{
    display: block;
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
  .search-regular__menu-filter{
    color: #44444F;
    font-size: 1.6rem;
    .label{      
      font-weight: 600;
      margin-bottom: 1.2rem;
      line-height: 1.8rem;
    }
    .filter-input{
      height: 4rem;
      width: 100%;
      line-height: 4rem;
      border-radius: 0.6rem;
      border: 1px solid #92929D99;
      box-sizing: border-box;
      padding: 1.1rem 1.2rem;
      padding-top:0;
      margin-bottom: 1.2rem;
      .mat-form-field-wrapper {
        padding:0;
        margin:0;
      }
      &.half{
        width: calc(50% - 20px);
        margin-right: 16px;
        display: inline-block;
        vertical-align: top;
        input{
          padding: 5px;
          width: calc(100% - 50px);
        }
      }
      .mat-form-field-label-wrapper{
        display: none;
      }
      .mat-form-field-infix{
        border-top:0 !important;
        padding:0;
        margin:0;
      }
      .mat-form-field-flex{
        padding:0;
      }
      .mat-form-field-outline-end, .mat-form-field-outline-start {
        border-color: transparent !important;
      }
      .mat-form-field-suffix{
          top: -5px
      }
    }
  }
}
.mat-calendar-body-cell-content{
  border-radius: 6px !important;
}

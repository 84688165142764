.title--32 {
  font-size: 3.2rem;
  font-weight: 900;
  line-height: 3.6rem;
}
.title--24 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 500;
}
.title--20 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 500;
}
.title--18,
tutor-user-search-result .name,
:host .name {
  font-size: 1.8rem;
  line-height: 1.6em;
  font-weight: 500;
}
.text--18 {
  font-size: 1.8rem;
  line-height: 1.6em;
}
.text--16,
tutor-user-search-result .rating,
:host .rating,
tutor-user-search-result .lessons,
:host .lessons,
tutor-user-search-result .disciplines,
:host .disciplines,
tutor-user-search-result .diplom,
:host .diplom {
  font-size: 1.6rem;
  line-height: 2.4rem;
}
tutor-user-search-result,
:host {
  --heart-border: #92929D;
  background: #fff;
  border-radius: 2rem;
  padding:2rem;
  flex: 0 0 calc(50% - 1rem);
  margin-bottom: 2rem;
  display: flex;
  position: relative;
  
  box-shadow: 0px 0px 13px 0px #678AFF4D;

  @media screen and (max-width: 850px){
    width: 100%;
    flex: none !important;
    padding: 1.6rem;
  }
  @media screen and (max-width: 450px){
    height: auto;
  }
  &.list{
    width: 100%;
    flex: none !important;
  }
}

tutor-user-search-result .image,
:host .image {
  width: 8rem;
  height: 8rem;
  margin-bottom: 1.3rem;
  position: relative;
  background: #ffc849;
  border-radius: 50%;
  height: 8rem;
  vertical-align: middle;
  text-align: center;
  font-size: 3rem;
  text-transform: uppercase;
  line-height: 8rem;
  color: #fff;
}

tutor-user-search-result .image .avatar-image,
:host .image .avatar-image {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
tutor-user-search-result .status,
:host .status {
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  background: #92929d;
  border-radius: 50%;
  display: block;
  bottom: 0.3rem;
  right: 0.6rem;
  border: 0.2rem solid #fff;
  margin-bottom: 0 !important;
  
}
tutor-user-search-result .status.online,
:host .status.online {
  background: #2bda5c;
}
tutor-user-search-result .info,
:host .info {
  flex: 0 0 9.5rem;
  margin-right: 1.8rem;
}

tutor-user-search-result .content,
:host .content {
  flex-grow: 1;
}
tutor-user-search-result .content__head,
:host .content__head {
  height: 12.5rem;
  @media screen and (max-width: 450px){
    height: auto;
  }
}
tutor-user-search-result .name,
:host .name {
  margin-bottom: 1rem;
}

tutor-user-search-result .rating,
:host .rating {
  font-weight: bold;
  width: 8rem;
  text-align: center;
  color: #505050;
  border-radius: 0.4rem;
  line-height: 1em;
  padding: 0;
  padding-bottom: 3rem;
  .star {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    background-size: 1.2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../assets/img/svg/icon-star.svg');
    &.filled{
      background-image: url('../../../assets/img/svg/icon-star-filled.svg');
    }
  }
}
/*tutor-user-search-result .rating.green,
:host .rating.green {
  background: rgba(130, 196, 60, 0.1);
  color: #82c43c;
}
tutor-user-search-result .rating.blue,
:host .rating.blue {
  background: rgba(80, 181, 255, 0.1);
  color: #50b5ff;
}
tutor-user-search-result .rating.yellow,
:host .rating.yellow {
  background: rgba(255, 200, 73, 0.1);
  color: #ffc849;
}*/
tutor-user-search-result .lessons,
:host .lessons {
  color: darkGrey;
  text-align: center;
}
tutor-user-search-result .disciplines,
:host .disciplines {
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
  color: #44444F;
  font-weight: 600;

}
tutor-user-search-result .disciplines__icon,
:host .disciplines__icon {
  flex: 0 0 2.5rem;
  width: 1.9rem;
  height: 1.7rem;
  margin-right: 0.8rem;
}
tutor-user-search-result .disciplines__name,
:host .disciplines__name {
  line-height: 1.2em;
}
tutor-user-search-result .actions,
:host .actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

tutor-user-search-result .actions .btn,
:host .actions .btn {
  z-index: 2;
  position: relative;
}
tutor-user-search-result .price__title,
:host .price__title {
  font-size: 1.4rem;
  color: #696974;
  line-height: 1.3em;
}
@media screen and (max-width: 1279.9px) {
  tutor-user-search-result .price__title,
  :host .price__title {
    font-size: 1.2rem;
  }
}
tutor-user-search-result .price__value,
:host .price__value {
  font-size: 2.1rem;
  color: #44444f;
  line-height: 1.3em;
}
tutor-user-search-result .portfolio{
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  justify-content: flex-start;
   .diplom,
  :host .diplom {
    display: flex;
    align-items: center;
    
    color: #92929D;
  }
   .diplom__icon,
  :host .diplom__icon {
    flex: 0 0 2.5rem;
    margin-right: 0.8rem;
  }
  .diplom__icon svg,
  :host .diplom__icon svg {
    margin-left: -0.3rem;
  }
  .diplom__name,
  :host .diplom__name {
    line-height: 1.2em;
    white-space: nowrap;
    margin-right: 1rem;
    @media screen and (max-width: 450px){
      white-space: normal;
    }
  }
  .reviews{
    display: flex;
    margin-right: 0.8rem;
    padding-left: 2.4rem;
    background-size: 1.6rem 1.6rem;
    background-repeat: no-repeat;
    background-position: left;
    background-image: url('../../../assets/img/svg/icon-reviews.svg');
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: #92929D;

  }
  .has-time{
    display: flex;
    margin-right: 0.8rem;
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: #92929D;
  }
  .good-rated{
    display: flex;
    margin-right: 8px;
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: #44444F;
    padding: 5px 12px;
    background-color: #FFC849;
    border-radius: 6px;
  }
  @media screen and (max-width: 700px) {
    white-space: normal;
    margin-bottom: 3rem;    
  }
  
}
tutor-user-search-result .bookmark,
:host .bookmark {
  position: absolute;
  top: 0.8rem;
  right: 0;
  color: transparent;
  z-index: 2;
  cursor: pointer;
}
tutor-user-search-result .bookmark svg,
:host .bookmark svg {
  width: 2.8rem;
  height: 4.2rem;
  pointer-event: none;
}
tutor-user-search-result .content.bookmarked .bookmark,
:host .content.bookmarked .bookmark {
  color: red;
  --heart-border: red;
}
tutor-user-search-result .linkfull,
:host .linkfull {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.requests__info,
.requests_item__info {
  margin-left: 3rem;
  border-left: 1px solid #ccc;
  padding-left: 3rem;
  margin-right: 3rem;
}
@media screen and (max-width: 1279.9px) {
  .requests__info,
  .requests_item__info {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    border-left: 0;
  }
}
.requests__header,
.requests_item__header {
  background: $blueColor;
  height: 5.6rem;
  border-radius: 2rem 2rem 0 0;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  justify-content: space-between;
}
@media screen and (max-width: 1279.9px) {
  .requests__header,
  .requests_item__header {
    padding: 0 2rem;
    font-size: 1.8rem;
  }
}
.requests_info__list,
.requests_item_info__list {
  display: flex;
  margin-bottom: 2rem;
}
.requests_info,
.requests_item_info {
  margin-right: 3rem;
}
.requests_info__name,
.requests_item_info__name {
  font-size: 1.4rem;
  font-weight: 500;
  color: #c4c4c4;
}
.requests_info__value,
.requests_item_info__value {
  font-size: 1.6rem;
}
.requests_msg,
.requests_item_msg {
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 0.8rem;
}
.requests_emptycards,
.requests_item_emptycards {
  font-size: 1.6rem;
  color: $redColor;
}
.requests_emptycards a,
.requests_item_emptycards a {
  color: $redColor;
  font-weight: 500;
  text-decoration: underline;
}
.requests_emptycards a:hover,
.requests_item_emptycards a:hover {
  text-decoration: none;
}
.requests__content,
.requests_item__content {
  display: flex;
  background: #fff;
  padding: 2rem 3rem;
  border-radius: 0 0 2rem 2rem;
  justify-content: space-between;
}
@media screen and (max-width: 1279.9px) {
  .requests__content,
  .requests_item__content {
    padding: 1.5rem 2rem;
    flex-wrap: wrap;
  }
}
.requests__profile,
.requests_item__profile {
  display: flex;
  align-items: start-flex;
  flex: 0 0 30rem;
}
.requests__image,
.requests_item__image {
  margin-right: 2rem;
  background: #ffc849;
  border-radius: 50%;
  width: 9rem;
  height: 9rem;
  vertical-align: middle;
  text-align: center;
  font-size: 3rem;
  text-transform: uppercase;
  line-height: 9rem;
  color: #fff;
}
.requests__image img,
.requests_item__image img {
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
}
.requests__name,
.requests_item__name {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}
.requests__actions,
.requests_item__actions {
  flex: 0 0 21.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.requests__actions .btn + .btn,
.requests_item__actions .btn + .btn {
  margin-top: 1rem;
}
.tutor-request-margin {
  margin-bottom: 2rem;
}

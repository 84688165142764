.title--32 {
  font-size: 3.2rem;
  font-weight: 900;
  line-height: 3.6rem;
}
.title--24 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 500;
}
.title--20 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 500;
}
.title--18 {
  font-size: 1.8rem;
  line-height: 1.6em;
  font-weight: 500;
}
.text--18 {
  font-size: 1.8rem;
  line-height: 1.6em;
}
.text--16 {
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.hint_block {
  background: #f6f6f6;
  padding: 2rem 4.5rem;
  border-radius: 0.8rem;
  color: #44444f;
  position: relative;
  &:before {
    font-size: 1.4rem;
    width: 1.4rem;
    height: 1.5rem;
    display: block;
    position: absolute;
    left: 2rem;
    top: 2.2rem;
    background: url('/assets/img/svg/icon-hint.svg');
    content: ' ';
    object-fit: contain;
  }
}

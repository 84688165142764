.title--32,
.form_search__title {
  font-size: 3.2rem;
  font-weight: 900;
  line-height: 3.6rem;
}
.title--24 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 500;
}
.title--20,
.filter__title,
.form_search__subtitle {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 500;
}
.title--18 {
  font-size: 1.8rem;
  line-height: 1.6em;
  font-weight: 500;
}
.text--18 {
  font-size: 1.8rem;
  line-height: 1.6em;
}
.text--16 {
  font-size: 1.6rem;
  line-height: 2.4rem;
}
form-search-instant {
  flex: 0 0 100%;
}
form-search-regular {
  flex: 0 0 100%;
}
history {
  flex: 0 0 50%;
}
.filter {
  background: #fff;
  margin-bottom: 2rem;
  border-radius: 2rem;
  padding: 2rem 3rem;
}
.filter__title {
  font-weight: bold;
}
.search__list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.search__action {
  text-align: center;
  margin-top: 2rem;
}
.form_search {
  margin-bottom: 2rem;
  display: block;
  border-radius: 2rem;
}
.form_search mat-radio-group {
  display: block;
  margin-bottom: 0.5rem;
}
.form_search mat-form-field {
  width: 100%;
}
.form_search textarea {
  height: 5rem;
  resize: none;
}
.form_search__header {
  height: 16.2rem;
  border-radius: 2rem 2rem 0 0;
  padding: 3rem 3rem;
  position: relative;
}
@media screen and (max-width: 1279.9px) {
  .form_search__header {
    padding: 2rem;
    height: 14rem;
  }
}
form-search-regular .form_search__header {
  background: #979ebb url("/assets/img/content/bg_search_regular.svg") no-repeat 100% 50%;
}
form-search-instant .form_search__header {
  background: #678aff url("/assets/img/content/bg_search_blitz.svg") no-repeat 100% 50%;
}
.form_search__title {
  color: #fff;
  margin-bottom: 1.4rem;
}
@media screen and (max-width: 1279.9px) {
  .form_search__title {
    font-size: 2.6rem;
  }
}
.form_search__subtitle {
  color: #fff;
}
@media screen and (max-width: 1279.9px) {
  .form_search__subtitle {
    font-size: 1.6rem;
  }
}
.form_search__icon {
  width: 11.4rem;
  position: absolute;
  right: 1.5rem;
  bottom: -3.5rem;
}
.form_search__content {
  padding: 1.5rem 3rem;
  background: #fff;
  border-radius: 0 0 2rem 2rem;
}
@media screen and (max-width: 1279.9px) {
  .form_search__content {
    padding: 1.5rem 2rem;
  }
}
.form_search__col {
  margin-right: 3rem;
  flex: 0 0 26rem;
}
@media screen and (max-width: 1279.9px) {
  .form_search__col {
    margin-right: 0;
  }
}
.form_search__col.full {
  flex: 1 0 auto;
}
.form_search__col:last-child {
  margin-right: 0;
}
.form_search .textarea textarea {
  resize: none;
}
.form_search .counterHint {
  margin-top: 0.9rem;
}
.form_search .spinner {
  display: inline-block;
  box-shadow: inset 2px 0 #e2e2ea, inset 0 2px #e2e2ea, inset -2px 0 #e2e2ea, inset 0 -2px #e2e2ea;
  border-radius: 50%;
  -moz-transform: translateY(15%);
  -webkit-transform: translateY(15%);
  transform: translateY(15%);
}
.form_search .hint,
.form_search__button {
  flex: 0 0 12rem;
  text-align: right;
}
.form_search__line {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
@media screen and (max-width: 1279.9px) {
  .form_search__line {
    display: block;
  }
}
.form_search__line mat-radio-button {
  margin-right: 2rem;
}
@media screen and (max-width: 1279.9px) {
  .form_search__line mat-radio-button {
    font-size: 1.4rem;
  }
}
.form_search__line mat-radio-button:last-child {
  margin-right: 0;
}
.form_search__line.one {
  margin-top: 3rem;
}
.form_search__line.last {
  align-items: flex-end;
}
.form_search__line.last .form_search__button {
  padding-bottom: 2.2rem;
}
@media screen and (max-width: 1279.9px) {
  .form_search__line.last .form_search__button {
    padding-bottom: 0;
    margin-top: 2rem;
  }
  .form_search__line.last .form_search__button .btn {
    width: 100%;
    text-align: center;
  }
  .form_search__line.last .form_search__button .btn .btn__label {
    width: 100%;
  }
}
.form_search .mat-form-field-underline {
  display: block;
}

.mat-radio-button {
  &.mat-accent {
    .mat-radio-outer-circle {
      border-width: 2px;
    }
    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: #92929d;
      }
    }
  }
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: $blueColor;
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-width: 1px !important;
}
.mat-form-field-appearance-outline {
  .mat-form-field-outline-thick {
    color: #c4c4c4 !important;
    opacity: 1 !important;
  }
}
.mat-form-field-appearance-legacy {
  .mat-form-field-underline {
    background-color: #c4c4c4 !important;
  }
}

@import 'common.scss';

.title--32 {
  font-size: 3.2rem;
  font-weight: 900;
  line-height: 3.6rem;
}

.title--24,
.user_page_header__name {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 500;
}

.title--20 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 500;
}

.title--18 {
  font-size: 1.8rem;
  line-height: 1.6em;
  font-weight: 500;
}

.text--18 {
  font-size: 1.8rem;
  line-height: 1.6em;
}

.text--16,
.user_page_header__disciplines_value {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.user_page__content {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1279.9px) {
  .user_page__content {
    display: block;
  }
}

.user_page__left {
  flex: 0 0 28rem;
  margin-right: 2rem;
}

@media screen and (max-width: 1279.9px) {
  .user_page__left {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

.user_page__right {
  flex: 1 0 auto;
}

.user_page_block {
  background: #fff;
  margin-bottom: 2rem;
  border-radius: 1rem;
}

.user_page_block__title {
  padding: 2rem 2rem 0;
  font-weight: 500;
  font-size: 1.6rem;
}

.user_page_block__content {
  padding: 2rem;
}

.user_page_block:last-child {
  margin-bottom: 0;
}

.user_page__left .user_page_block .user_page_block__title {
  border-bottom: 1px solid #e2e2ea;
  padding-bottom: 2rem;
}

.user_page_icons {
  display: flex;
  flex-wrap: wrap;
}

.user_page_icon {
  flex: 0 0 25%;
  text-align: center;
}

@media screen and (max-width: 1279.9px) {
  .user_page_icon {
    flex: 0 0 100%;
    margin-bottom: 3rem;
  }

  .user_page_icon:last-child {
    margin-bottom: 0;
  }
}

.user_page_icon__image {
  margin: 0 auto 1.5rem;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: rgba(103, 138, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_page_icon__name {
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.user_page_icon__text {
  color: #678aff;
  font-size: 1.4rem;
}

.user_page_message .message_input {
  width: 100%;
  margin-bottom: 0;
}

.user_page__tag {
  background: rgba(103, 138, 255, 0.1);
  padding: 1rem 1.5rem;
  margin-bottom: 1.2rem;
  display: inline-block;
  border-radius: 0.5rem;
  color: #44444f;
  font-size: 1.4rem;
  line-height: 1.3em;
  margin-right: 1.2rem;
}

.user_page__tag:last-child {
  margin-bottom: 0;
}

.user_page .confirm {
  height: 2.1rem;
  vertical-align: middle;
}

.user_page .confirm img {
  width: 2.1rem;
  height: 2.1rem;
}

.user_page_header {
  position: relative;
  margin-bottom: 2rem;
}

.user_page_header__rate {
  position: absolute;
  top: 7rem;
  left: 60rem;
  line-height: 2.4rem;
  display: flex;
}

.user_page_header__rate span {
  font-size: 2.4rem;
  font-weight: 500;
  color: #fff;
  margin-right: 1rem;
}

.user_page_header__rate img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.3rem;
}

.user_page_header__menu {
  position: absolute;
  left: 30rem;
  bottom: -0.2rem;
}

.user_page_header__menu a {
  position: relative;
  padding: 1.5rem;
  display: inline-block;
  text-align: center;
  margin-right: 1rem;
}

.user_page_header__menu a.active {
  color: #678aff;
  font-weight: bold;
}

.user_page_header__menu a.active:after {
  height: 0.3rem;
  background: #678aff;
  border-radius: 0.5rem 0.5rem 0 0;
  content: ' ';
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.user_page_header__image {
  position: absolute;
  top: 4.5rem;
  left: 2.2rem;
  width: 18rem;
  height: 18rem;
  z-index: 1;
  background: #ffc849;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  font-size: 4rem;
  text-transform: uppercase;
  line-height: 18rem;
  color: #fff;
}

.user_page_header__image .status {
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  position: absolute;
  top: 1.5rem;
  right: 0.6rem;
  background: #92929d;
  border: 2px solid $purpleColor;
}

.user_page_header__image .status.online {
  background: #2bda5c;
}

.user_page_header__image .avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.user_page_header__top {
  height: 18rem;
  background: $purpleColor;
  border-radius: 2rem 2rem 0 0;
  padding: 7rem 3rem 3rem 23rem;
  position: relative;
}

.user_page_header__bottom {
  background: #fff;
  border-radius: 0 0 2rem 2rem;
  padding: 1.5rem 3rem;
  height: 8rem;
  position: relative;
}

.user_page_header__name {
  color: #fff;
  margin-bottom: 1rem;
}

.user_page_header .bookmark {
  position: absolute;
  top: -3px;
  right: 4.5rem;
  color: transparent;
  z-index: 2;
  cursor: pointer;
}

.user_page_header .bookmark svg {
  width: 2.8rem;
  height: 4.2rem;
  pointer-event: none;
}

.user_page_header.bookmarked .bookmark {
  color: $blueColor;
}

.user_page_header__icon {
  position: absolute;
  top: 8rem;
  right: 4.5rem;
  z-index: 2;
}

.user_page_header__icon svg {
  width: 24.7rem;
  height: 11.5rem;
}

.user_page_header__disciplines span {
  color: #c4c4c4;
  font-size: 1.4rem;
}

.user_page_header__disciplines_value {
  color: #fff;
}

.user_page .mobile {
  display: none;
}

@media screen and (max-width: 1279.9px) {
  .user_page .mobile {
    display: block !important;
  }

  .user_page_header__top {
    border-radius: 1rem 1rem 0 0;
  }

  .user_page_header__top .user_page_header__name,
  .user_page_header__top .user_page_header__disciplines,
  .user_page_header__top .user_page_header__rate {
    display: none;
  }

  .user_page_header__top .user_page_header__icon {
    top: 4rem;
    z-index: 2;
  }

  .user_page_header__image {
    width: 14rem;
    height: 14rem;
    z-index: 3;
    left: 50%;
    margin-left: -7rem;
    top: 10rem;
    line-height: 14rem;
  }

  .user_page_header__menu {
    position: inherit;
    background: #fff;
    border-radius: 1rem;
    padding: 0 2rem;
    margin-bottom: 2rem;
  }

  .user_page_header__bottom {
    border-radius: 0 0 1rem 1rem;
    padding-top: 7rem;
    text-align: center;
    height: inherit;
    padding-bottom: 2rem;
  }

  .user_page_header__bottom .user_page_header__disciplines_value {
    color: #000;
  }

  .user_page_header__bottom .user_page_header__rate {
    position: inherit;
    left: auto;
    top: auto;
    margin-top: 2rem;
    display: flex !important;
    justify-content: center;
  }

  .user_page_header__bottom .user_page_header__rate span {
    color: #000;
  }

  .user_page_header__bottom .user_page_header__menu {
    display: none;
  }

  .user_page_header__bottom .user_page_header__name {
    color: #000;
    font-size: 1.6rem;
    font-weight: 500;
  }

  .user_page_header .bookmark {
    right: 3rem;
  }
}
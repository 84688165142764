.title--32 {
  font-size: 3.2rem;
  font-weight: 900;
  line-height: 3.6rem;
}
.title--24 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 500;
}
.title--20 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 500;
}
.title--18 {
  font-size: 1.8rem;
  line-height: 1.6em;
  font-weight: 500;
}
.text--18 {
  font-size: 1.8rem;
  line-height: 1.6em;
}
.text--16,
.bookmark_item__rating,
.bookmark_item__lessons,
.bookmark_item .diplom,
.bookmark_item .disciplines {
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.widget_bookmarks {
  background: #fff;
  border-radius: 2rem;
  padding: 4rem 3rem;
  margin-bottom: 2rem;
  position: relative;
  height: 100%;
}
.widget_bookmarks-history__list {
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;
}
@media screen and (max-width: 1279.9px) {
  .widget_bookmarks-history__list {
    margin-bottom: 2rem;
  }
}
.widget_bookmarks-history__all {
  display: flex;
  justify-content: center;
}
.widget_bookmarks-history__all a {
  color: #678aff;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 500;
}
.widget_bookmarks__icon {
  width: 6.2rem;
  height: 6.2rem;
  position: absolute;
  right: 7rem;
  top: 0;
}
@media screen and (max-width: 1279.9px) {
  .widget_bookmarks__icon {
    right: 2rem;
  }
}
.widget_bookmarks__title {
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.4rem;
  margin-bottom: 3rem;
}
.widget_bookmarks_none {
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.widget_bookmarks_none__wrap {
  width: 30rem;
  margin: 0 auto;
}
.widget_bookmarks_none__icon {
  text-align: center;
  margin-bottom: 2rem;
}
.widget_bookmarks_none__icon svg {
  width: 8.6rem;
  height: 8rem;
  display: inline-block;
}
.widget_bookmarks_none__title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.widget_bookmarks_none__text {
  font-size: 1.6rem;
  margin-bottom: 2rem;
}
.widget_bookmarks_none__button {
  text-align: center;
}
.widget_bookmarks__list {
  height: 36rem;
  overflow: auto;
}
@media screen and (max-width: 1279.9px) {
  .widget_bookmarks__list {
    height: auto;
  }
}
.widget_bookmarks__finance {
  height: 100%;
}
.widget_bookmarks__finance__content {
  height: 100%;
  display: flex;
}
.widget_bookmarks__finance__content__alltime {
  width: 300px;
  border-right: 1px solid #e2e2ea;
}
.widget_bookmarks__finance__content__stat {
  padding-left: 40px;
}
.bookmark_item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2ea;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 1279.9px) {
  .bookmark_item {
    flex-wrap: wrap;
  }
}
.bookmark_item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}
.bookmark_item__image {
  width: 4.5rem;
  margin-right: 2rem;
  font-size: 0;
  flex: 0 0 4.5rem;
  background: #ffc849;
  border-radius: 50%;
  height: 4.5rem;
  vertical-align: middle;
  text-align: center;
  font-size: 2rem;
  text-transform: uppercase;
  line-height: 4.5rem;
  color: #fff;
}
.bookmark_item__image img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
}
.bookmark_item__info {
  flex: 1 1 auto;
}
@media screen and (max-width: 1279.9px) {
  .bookmark_item__info {
    flex: 1 1 16rem;
    margin-bottom: 2rem;
  }
}
.bookmark_item__name {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
}
.bookmark_item__stat {
  flex: 0 0 8rem;
}
.bookmark_item__rating {
  font-weight: bold;
  width: 8rem;
  text-align: center;
  margin: 0 auto 0.7rem;
  background: rgba(80,80,80,0.1);
  color: #505050;
  border: 1px solid currentColor;
  border-radius: 0.4rem;
  line-height: 1em;
  padding: 0.5rem 0;
}
.bookmark_item__rating.green {
  background: rgba(130,196,60,0.1);
  color: #82c43c;
}
.bookmark_item__rating.blue {
  background: rgba(80,181,255,0.1);
  color: #50b5ff;
}
.bookmark_item__rating.yellow {
  background: rgba(255,200,73,0.1);
  color: #ffc849;
}
.bookmark_item__lessons {
  color: darkGrey;
  text-align: center;
}
.bookmark_item__price {
  margin-left: 2rem;
  flex: 0 0 12rem;
}
@media screen and (max-width: 1279.9px) {
  .bookmark_item__price {
    margin-left: 0;
  }
}
.bookmark_item .price__title {
  font-size: 1.4rem;
  color: #696974;
  line-height: 1.3em;
}
.bookmark_item .price__value {
  font-size: 2.1rem;
  color: #44444f;
  line-height: 1.3em;
}
.bookmark_item .diplom {
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
  color: #696974;
}
.bookmark_item .diplom__icon {
  flex: 0 0 2.5rem;
  margin-right: 0.8rem;
}
.bookmark_item .diplom__icon svg {
  margin-left: -0.3rem;
}
.bookmark_item .diplom__name {
  line-height: 1.2em;
}
.bookmark_item .disciplines {
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
  color: #696974;
}
.bookmark_item .disciplines__icon {
  flex: 0 0 2.5rem;
  width: 1.9rem;
  height: 1.7rem;
  margin-right: 0.8rem;
}
.bookmark_item .disciplines__name {
  line-height: 1.2em;
}
.label-y {
  font-size: 16px;
}
.label-x {
  font-size: 9px;
}

.tippy-box[data-animation='fade'][data-state='hidden'] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  position: relative;
  background-color: #fff;
  color: #000;
  border-radius: 16px;
  font-size: 14px;
  line-height: 1.4;
  outline: 0;
  transition-property: transform, visibility, opacity;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

.tippy-box[data-placement^='top'] {
  &>.tippy-arrow {
    bottom: 0;

    &:before {
      bottom: -7px;
      left: 0;
      border-width: 8px 8px 0;
      border-top-color: initial;
      transform-origin: center top;
    }
  }
}

.tippy-box[data-placement^='bottom'] {
  &>.tippy-arrow {
    top: 0;

    &:before {
      top: -7px;
      left: 0;
      border-width: 0 8px 8px;
      border-bottom-color: initial;
      transform-origin: center bottom;
    }
  }
}

.tippy-box[data-placement^='left'] {
  &>.tippy-arrow {
    right: 0;

    &:before {
      border-width: 8px 0 8px 8px;
      border-left-color: initial;
      right: -7px;
      transform-origin: center left;
    }
  }
}

.tippy-box[data-placement^='right'] {
  &>.tippy-arrow {
    left: 0;

    &:before {
      left: -7px;
      border-width: 8px 8px 8px 0;
      border-right-color: initial;
      transform-origin: center right;
    }
  }
}

.tippy-box[data-inertia][data-state='visible'] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #fff;

  &:before {
    content: '';
    position: absolute;
    border-color: transparent;
    border-style: solid;
  }
}

.tippy-content {
  position: relative;
  padding: 5px 9px;
  font-size: 16px;
  line-height: 22px;
  display: block;
  position: relative;
  max-width: 435px;
  margin: 0 auto;
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 3px 9px 104px rgba(0, 0, 0, 0.2);
  border-radius: 16px !important;
  padding: 15px 10px;
  animation: fadeIn 0.5s linear;
  z-index: 1;
}

.tippy-box[data-theme~='tooltip'] {
  background: #f6f6f6;
  color: #44444f !important;
  font-size: 12px;
  margin: 0;
  max-width: 186px !important;
  display: flex !important;

  .tippy-content {
    display: flex;
    padding: 8px 11px;
    color: #44444f;
  

  }

  .tooltip-notification__denied {
    display: flex;
  }

  .icon-block {
    display: flex;
    margin-right: 8px;

    .icon-wrapper {
      width: 14px;
    }
  }
}

.tippy-box[data-theme~='whiteboard-tooltip'],
.tippy-box[data-theme~='homework-tooltip'] {
  background: #44444f;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;

  .tippy-arrow {
    color: #44444f;
  }
}

div[data-tippy-root] {
  z-index: 10000000000 !important;
}
.fc-icon,
.fc-unselectable {
  -moz-user-select: none;
  -ms-user-select: none;
}
.fc .fc-button,
.fc-icon {
  text-transform: none;
  text-align: center;
  display: inline-block;
  font-weight: 400;
}
.fc-not-allowed,
.fc-not-allowed .fc-event {
  cursor: not-allowed;
}
.fc .fc-button:not(:disabled),
.fc a[data-navlink],
.fc-event.fc-event-draggable,
.fc-event[href] {
  cursor: pointer;
}
.fc-unselectable {
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.fc {
  display: flex;
  flex-direction: column;
  font-size: 1em;
  table {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1em;
  }
  th {
    text-align: center;
  }
  a[data-navlink]:hover {
    text-decoration: underline;
  }
  .fc-button {
    overflow: visible;
    text-transform: none;
    margin: 0;
    font-family: inherit;
    -webkit-appearance: button;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.4em 0.65em;
    font-size: 1em;
    line-height: 1.5;
    border-radius: 0.25em;
    &::-moz-focus-inner {
      padding: 0;
      border-style: none;
    }
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
    }
    &:disabled {
      opacity: 0.65;
    }
    .fc-icon {
      font-size: 1.5em;
    }
  }
  .fc-button-primary {
    color: #fff;
    color: var(--fc-button-text-color, #fff);
    background-color: #2c3e50;
    background-color: var(--fc-button-bg-color, #2c3e50);
    border-color: #2c3e50;
    border-color: var(--fc-button-border-color, #2c3e50);
    &:hover {
      color: #fff;
      color: var(--fc-button-text-color, #fff);
      background-color: #1e2b37;
      background-color: var(--fc-button-hover-bg-color, #1e2b37);
      border-color: #1a252f;
      border-color: var(--fc-button-hover-border-color, #1a252f);
    }
    &:disabled {
      color: #fff;
      color: var(--fc-button-text-color, #fff);
      background-color: #2c3e50;
      background-color: var(--fc-button-bg-color, #2c3e50);
      border-color: #2c3e50;
      border-color: var(--fc-button-border-color, #2c3e50);
    }
  }
  .fc-button-group {
    position: relative;
    display: inline-flex;
    & > .fc-button {
      position: relative;
      flex: 1 1 auto;
    }
  }
  .fc-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.fc-header-toolbar {
      margin-bottom: 1.5em;
    }
    &.fc-footer-toolbar {
      margin-top: 1.5em;
    }
  }
  .fc-toolbar-title {
    font-size: 1.75em;
    margin: 0;
  }
  .fc-scroller {
    -webkit-overflow-scrolling: touch;
    position: relative;
  }
  .fc-scroller-liquid {
    height: 100%;
  }
  .fc-scroller-liquid-absolute {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .fc-scroller-harness {
    position: relative;
    overflow: hidden;
    direction: ltr;
  }
  .fc-scroller-harness-liquid {
    height: 100%;
  }
  .fc-scrollgrid {
    border-collapse: separate;
    border-right-width: 0;
    table {
      border-top-style: hidden;
      border-left-style: hidden;
      border-right-style: hidden;
    }
  }
  .fc-scrollgrid-liquid {
    height: 100%;
  }
  .fc-scrollgrid-section-liquid {
    height: auto;
    & > td {
      height: 100%;
    }
  }
  .fc-scrollgrid-section {
    & > * {
      border-top-width: 0;
      border-left-width: 0;
    }
  }
  .fc-scrollgrid-section-sticky {
    & > * {
      background: var(--fc-page-bg-color, #fff);
      position: -webkit-sticky;
      position: sticky;
      z-index: 2;
    }
  }
  .fc-scrollgrid-section-header {
    &.fc-scrollgrid-section-sticky {
      & > * {
        top: 0;
      }
    }
  }
  .fc-scrollgrid-section-footer {
    &.fc-scrollgrid-section-sticky {
      & > * {
        bottom: 0;
      }
    }
  }
  .fc-scrollgrid-sticky-shim {
    height: 1px;
    margin-bottom: -1px;
  }
  .fc-view-harness {
    flex-grow: 1;
    position: relative;
  }
  .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 2px 4px;
  }
  .fc-non-business {
    background: rgba(215, 215, 215, 0.3);
    background: var(--fc-non-business-color, rgba(215, 215, 215, 0.3));
  }
  .fc-bg-event {
    background: var(--fc-bg-event-color, #8fdf82);
    opacity: 0.3;
    opacity: var(--fc-bg-event-opacity, 0.3);
    .fc-event-title {
      margin: 0.5em;
      font-size: 0.85em;
      font-size: var(--fc-small-font-size, 0.85em);
      font-style: italic;
    }
  }
  .fc-highlight {
    background: rgba(188, 232, 241, 0.3);
    background: var(--fc-highlight-color, rgba(188, 232, 241, 0.3));
  }
  .fc-popover {
    position: fixed;
    top: 0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }
  .fc-popover-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3px 4px;
  }
  .fc-popover-title {
    margin: 0 2px;
  }
  .fc-popover-close {
    cursor: pointer;
    opacity: 0.65;
    font-size: 1.1em;
  }
  .fc-daygrid-body {
    position: relative;
    z-index: 1;
  }
  .fc-daygrid-day {
    &.fc-day-today {
      background-color: rgba(255, 220, 40, 0.15);
      background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15));
    }
  }
  .fc-daygrid-day-frame {
    position: relative;
    min-height: 100%;
  }
  .fc-daygrid-day-top {
    display: flex;
    flex-direction: row-reverse;
  }
  .fc-day-other {
    .fc-daygrid-day-top {
      opacity: 0.3;
    }
  }
  .fc-daygrid-day-number {
    cursor: auto;
    position: relative;
    z-index: 4;
    padding: 4px;
  }
  .fc-daygrid-day-events {
    margin-top: 1px;
  }
  .fc-daygrid-body-balanced {
    .fc-daygrid-day-events {
      position: absolute;
      left: 0;
      right: 0;
    }
  }
  .fc-daygrid-body-unbalanced {
    .fc-daygrid-day-events {
      position: relative;
      min-height: 2em;
    }
  }
  .fc-daygrid-body-natural {
    .fc-daygrid-day-events {
      margin-bottom: 1em;
    }
  }
  .fc-daygrid-event-harness {
    position: relative;
  }
  .fc-daygrid-event-harness-abs {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .fc-daygrid-bg-harness {
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .fc-daygrid-day-bg {
    .fc-non-business {
      z-index: 1;
    }
    .fc-bg-event {
      z-index: 2;
    }
    .fc-highlight {
      z-index: 3;
    }
  }
  .fc-daygrid-event {
    z-index: 6;
    margin-top: 1px;
    &.fc-event-mirror {
      z-index: 7;
    }
  }
  .fc-daygrid-day-bottom {
    font-size: 0.85em;
    margin: 2px 3px 0;
  }
  .fc-daygrid-more-link {
    position: relative;
    z-index: 4;
    cursor: pointer;
  }
  .fc-daygrid-week-number {
    position: absolute;
    z-index: 5;
    top: 0;
    padding: 2px;
    min-width: 1.5em;
    text-align: center;
    background-color: rgba(208, 208, 208, 0.3);
    background-color: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
    color: #808080;
    color: var(--fc-neutral-text-color, #808080);
  }
  .fc-more-popover {
    z-index: 8;
    .fc-popover-body {
      min-width: 220px;
      padding: 10px;
    }
  }
  .fc-timegrid {
    .fc-daygrid-body {
      z-index: 2;
    }
  }
  .fc-timegrid-divider {
    padding: 0 0 2px;
  }
  .fc-timegrid-body {
    min-height: 100%;
  }
  .fc-timegrid-axis-chunk {
    position: relative;
  }
  .fc-timegrid-slot {
    height: 1.5em;
    &:empty {
      &:before {
        content: '\00a0';
      }
    }
  }
  .fc-timegrid-axis-frame-liquid {
    height: 100%;
  }
  .fc-timegrid-axis-frame {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .fc-timegrid-axis-cushion {
    max-width: 60px;
    flex-shrink: 0;
  }
  .fc-timegrid-col {
    &.fc-day-today {
      background-color: rgba(255, 220, 40, 0.15);
      background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15));
    }
  }
  .fc-timegrid-col-frame {
    min-height: 100%;
    position: relative;
  }
  .fc-timegrid-col-bg {
    z-index: 2;
    .fc-non-business {
      z-index: 1;
    }
    .fc-bg-event {
      z-index: 2;
    }
  }
  .fc-timegrid-bg-harness {
    position: absolute;
    left: 0;
    right: 0;
  }
  .fc-timegrid-now-indicator-container {
    bottom: 0;
    overflow: hidden;
  }
  .fc-timegrid-now-indicator-line {
    position: absolute;
    z-index: 4;
    left: 0;
    right: 0;
    border-style: solid;
    border-color: #f00;
    border-color: var(--fc-now-indicator-color, #f00);
    border-width: 1px 0 0;
  }
  .fc-timegrid-now-indicator-arrow {
    position: absolute;
    z-index: 4;
    margin-top: -5px;
    border-style: solid;
    border-color: #f00;
    border-color: var(--fc-now-indicator-color, #f00);
  }
  .fc-list-empty {
    background-color: rgba(208, 208, 208, 0.3);
    background-color: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fc-list-empty-cushion {
    margin: 5em 0;
  }
  .fc-list-table {
    width: 100%;
    border-style: hidden;
    tr {
      & > * {
        border-left: 0;
        border-right: 0;
      }
    }
    th {
      padding: 0;
    }
  }
  .fc-list-sticky {
    .fc-list-day {
      & > * {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: var(--fc-page-bg-color, #fff);
      }
    }
  }
  .fc-list-day-cushion {
    &:after {
      content: '';
      clear: both;
      display: table;
    }
  }
  .fc-list-event {
    &.fc-event-forced-url {
      cursor: pointer;
      &:hover {
        a {
          text-decoration: underline;
        }
      }
    }
    &:hover {
      td {
        background-color: #f5f5f5;
        background-color: var(--fc-list-event-hover-bg-color, #f5f5f5);
      }
    }
  }
  .fc-list-event-dot {
    display: inline-block;
    box-sizing: content-box;
    width: 0;
    height: 0;
    border: 5px solid #3788d8;
    border: calc(var(--fc-list-event-dot-width, 10px) / 2) solid var(--fc-event-border-color, #3788d8);
    border-radius: 5px;
    border-radius: calc(var(--fc-list-event-dot-width, 10px) / 2);
  }
  .fc-list-event-title {
    a {
      color: inherit;
    }
  }
}
.fc .fc-timegrid-slot-minor,
.fc .fc-timegrid-slot-label-cushion {
  white-space: nowrap;
}
.fc,
.fc *,
.fc :after,
.fc :before {
  box-sizing: border-box;
}
.fc td,
.fc th {
  vertical-align: top;
  padding: 0;
}
.fc .fc-button,
.fc .fc-button .fc-icon,
.fc .fc-button-group,
.fc .fc-timegrid-slot-label {
  vertical-align: middle;
}
.fc .fc-button:hover,
.fc .fc-list-event-title a,
a.fc-event,
a.fc-event:hover {
  text-decoration: none;
}
.fc-direction-ltr {
  direction: ltr;
  text-align: left;
  .fc-button-group {
    & > .fc-button {
      &:not(:first-child) {
        margin-left: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  .fc-toolbar {
    & > * {
      & > :not(:first-child) {
        margin-left: 0.75em;
      }
    }
  }
  .fc-daygrid-week-number {
    left: 0;
    border-radius: 0 0 3px;
  }
  .fc-daygrid-event {
    .fc-event-time {
      margin-right: 3px;
    }
  }
  .fc-timegrid-slot-label-frame {
    text-align: right;
  }
  .fc-timegrid-col-frame {
    margin: 0 2px 0 0px;
  }
  .fc-timegrid-now-indicator-arrow {
    left: 0;
    border-width: 5px 0 5px 6px;
    border-top-color: transparent;
    border-bottom-color: transparent;
  }
  .fc-list-table {
    .fc-list-event-graphic {
      padding-right: 0;
    }
  }
}
.fc-direction-rtl {
  direction: rtl;
  text-align: right;
  .fc-button-group {
    & > .fc-button {
      &:not(:first-child) {
        margin-right: -1px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:not(:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  .fc-toolbar {
    & > * {
      & > :not(:first-child) {
        margin-right: 0.75em;
      }
    }
  }
  .fc-toolbar-ltr {
    flex-direction: row-reverse;
  }
  .fc-scroller-harness {
    & > .fc-scroller {
      direction: rtl;
    }
  }
  .fc-daygrid-week-number {
    right: 0;
    border-radius: 0 0 0 3px;
  }
  .fc-daygrid-event {
    .fc-event-time {
      margin-left: 3px;
    }
  }
  .fc-timegrid-slot-label-frame {
    text-align: left;
  }
  .fc-timegrid-col-events {
    margin: 0 2px 0 2.5%;
  }
  .fc-timegrid-now-indicator-arrow {
    right: 0;
    border-width: 5px 6px 5px 0;
    border-top-color: transparent;
    border-bottom-color: transparent;
  }
  .fc-list-table {
    .fc-list-event-graphic {
      padding-left: 0;
    }
  }
}
.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #ddd;
  border: 1px solid var(--fc-border-color, #ddd);
}
.fc-liquid-hack td,
.fc-liquid-hack th {
  position: relative;
}
@font-face {
  font-family: fcicons;
  font-weight: 400;
  font-style: normal;
}
.fc-icon {
  width: 1em;
  height: 1em;
  -webkit-user-select: none;
  user-select: none;
  font-family: fcicons !important;
  speak: none;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fc-icon-chevron-left {
  &:before {
    content: '\e900';
  }
}
.fc-icon-chevron-right {
  &:before {
    content: '\e901';
  }
}
.fc-icon-chevrons-left {
  &:before {
    content: '\e902';
  }
}
.fc-icon-chevrons-right {
  &:before {
    content: '\e903';
  }
}
.fc-icon-minus-square {
  &:before {
    content: '\e904';
  }
}
.fc-icon-plus-square {
  &:before {
    content: '\e905';
  }
}
.fc-icon-x {
  &:before {
    content: '\e906';
  }
}
.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 91, 106, 0.5);
}
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #1a252f;
  background-color: var(--fc-button-active-bg-color, #1a252f);
  border-color: #151e27;
  border-color: var(--fc-button-active-border-color, #151e27);
}
.fc .fc-button-group > .fc-button.fc-button-active,
.fc .fc-button-group > .fc-button:active,
.fc .fc-button-group > .fc-button:focus,
.fc .fc-button-group > .fc-button:hover {
  z-index: 1;
}
.fc-theme-standard {
  .fc-scrollgrid {
    border: 1px solid #ddd;
    border: 1px solid var(--fc-border-color, #ddd);
  }
  .fc-popover {
    border: 1px solid #ddd;
    border: 1px solid var(--fc-border-color, #ddd);
    background: var(--fc-page-bg-color, #fff);
  }
  .fc-popover-header {
    background: rgba(208, 208, 208, 0.3);
    background: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
  }
  .fc-list {
    border: 1px solid #ddd;
    border: 1px solid var(--fc-border-color, #ddd);
  }
  .fc-list-day-cushion {
    background-color: rgba(208, 208, 208, 0.3);
    background-color: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
  }
}
.fc .fc-scrollgrid,
.fc .fc-scrollgrid-section-footer > *,
.fc .fc-scrollgrid-section-header > * {
  border-bottom-width: 0;
}
.fc .fc-scrollgrid,
.fc .fc-scrollgrid table {
  width: 100%;
  table-layout: fixed;
}
.fc .fc-scrollgrid-section-body table,
.fc .fc-scrollgrid-section-footer table {
  border-bottom-style: hidden;
}
.fc-sticky {
  position: -webkit-sticky;
  position: sticky;
}
.fc .fc-bg-event,
.fc .fc-highlight,
.fc .fc-non-business,
.fc .fc-view-harness-active > .fc-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fc .fc-cell-shaded,
.fc .fc-day-disabled {
  background: rgba(208, 208, 208, 0.3);
  background: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
}
.fc-event {
  margin-right: -0.2rem;
  .fc-event-main {
    position: relative;
    z-index: 2;
  }
  .fc-event-resizer {
    display: none;
    position: absolute;
    z-index: 4;
  }
}
.fc-event-dragging {
  &:not(.fc-event-selected) {
    opacity: 0.75;
  }
  &.fc-event-selected {
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
  }
}
.fc-event-selected .fc-event-resizer,
.fc-event:hover .fc-event-resizer,
.fc-h-event {
  display: block;
}
.fc-event-selected {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  .fc-event-resizer {
    border-radius: 4px;
    border-radius: calc(var(--fc-event-resizer-dot-total-width, 8px) / 2);
    border-width: 1px;
    border-width: var(--fc-event-resizer-dot-border-width, 1px);
    width: 8px;
    width: var(--fc-event-resizer-dot-total-width, 8px);
    height: 8px;
    height: var(--fc-event-resizer-dot-total-width, 8px);
    border-style: solid;
    border-color: inherit;
    background: var(--fc-page-bg-color, #fff);
    &:before {
      content: '';
      position: absolute;
      top: -20px;
      left: -20px;
      right: -20px;
      bottom: -20px;
    }
  }
  &:before {
    content: '';
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    background: rgba(0, 0, 0, 0.25);
    background: var(--fc-event-selected-overlay-color, rgba(0, 0, 0, 0.25));
    position: absolute;
    z-index: 1;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
  }
}
.fc-h-event {
  border: 1px solid #3788d8;
  border: 1px solid var(--fc-event-border-color, #3788d8);
  background-color: #3788d8;
  background-color: var(--fc-event-bg-color, #3788d8);
  .fc-event-main {
    color: #fff;
    color: var(--fc-event-text-color, #fff);
  }
  .fc-event-main-frame {
    display: flex;
  }
  .fc-event-time {
    max-width: 100%;
    overflow: hidden;
  }
  .fc-event-title-container {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
  }
  .fc-event-title {
    display: inline-block;
    vertical-align: top;
    left: 0;
    right: 0;
    max-width: 100%;
    overflow: hidden;
  }
  &.fc-event-selected {
    &:before {
      top: -10px;
      bottom: -10px;
    }
    .fc-event-resizer {
      top: 50%;
      margin-top: -4px;
      margin-top: calc(var(--fc-event-resizer-dot-total-width, 8px) / -2);
    }
  }
  &:not(.fc-event-selected) {
    .fc-event-resizer {
      top: 0;
      bottom: 0;
      width: 8px;
      width: var(--fc-event-resizer-thickness, 8px);
    }
  }
}
.fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-start),
.fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
}
.fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-end),
.fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-start) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
}
.fc-direction-ltr .fc-h-event:not(.fc-event-selected) .fc-event-resizer-start,
.fc-direction-rtl .fc-h-event:not(.fc-event-selected) .fc-event-resizer-end {
  cursor: w-resize;
  left: -4px;
  left: calc(var(--fc-event-resizer-thickness, 8px) / -2);
}
.fc-direction-ltr .fc-h-event:not(.fc-event-selected) .fc-event-resizer-end,
.fc-direction-rtl .fc-h-event:not(.fc-event-selected) .fc-event-resizer-start {
  cursor: e-resize;
  right: -4px;
  right: calc(var(--fc-event-resizer-thickness, 8px) / -2);
}
.fc-direction-ltr .fc-h-event.fc-event-selected .fc-event-resizer-start,
.fc-direction-rtl .fc-h-event.fc-event-selected .fc-event-resizer-end {
  left: -4px;
  left: calc(var(--fc-event-resizer-dot-total-width, 8px) / -2);
}
.fc-direction-ltr .fc-h-event.fc-event-selected .fc-event-resizer-end,
.fc-direction-rtl .fc-h-event.fc-event-selected .fc-event-resizer-start {
  right: -4px;
  right: calc(var(--fc-event-resizer-dot-total-width, 8px) / -2);
}
:root {
  --fc-daygrid-event-dot-width: 8px;
  --fc-list-event-dot-width: 10px;
  --fc-list-event-hover-bg-color: #f5f5f5;
}
.fc-daygrid-day-events:after,
.fc-daygrid-day-events:before,
.fc-daygrid-day-frame:after,
.fc-daygrid-day-frame:before,
.fc-daygrid-event-harness:after,
.fc-daygrid-event-harness:before {
  content: '';
  clear: both;
  display: table;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 2px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 2px;
}
.fc-liquid-hack {
  .fc-daygrid-day-frame {
    position: static;
  }
  .fc-timegrid-axis-frame-liquid {
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .fc-timegrid-col-frame {
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.fc-daygrid-event {
  position: relative;
  white-space: nowrap;
  border-radius: 3px;
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
}
.fc-daygrid-block-event {
  .fc-event-time {
    font-weight: 700;
  }
}
.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  padding: 1px;
}
.fc-daygrid-dot-event {
  display: flex;
  align-items: center;
  padding: 2px 0;
  .fc-event-title {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    overflow: hidden;
    font-weight: 700;
  }
  &.fc-event-selected {
    &:before {
      top: -10px;
      bottom: -10px;
    }
  }
}
.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
  background: rgba(0, 0, 0, 0.1);
}
.fc-daygrid-event-dot {
  margin: 0 4px;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border: 4px solid #3788d8;
  border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, #3788d8);
  border-radius: 4px;
  border-radius: calc(var(--fc-daygrid-event-dot-width, 8px) / 2);
}
.fc-v-event {
  display: block;
  border: 1px solid #3788d8;
  border: 1px solid var(--fc-event-border-color, #3788d8);
  background-color: #3788d8;
  background-color: var(--fc-event-bg-color, #3788d8);
  .fc-event-main {
    color: #fff;
    color: var(--fc-event-text-color, #fff);
    height: 100%;
  }
  .fc-event-main-frame {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .fc-event-time {
    flex-grow: 0;
    flex-shrink: 0;
    max-height: 100%;
    overflow: hidden;
  }
  .fc-event-title-container {
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 0;
  }
  .fc-event-title {
    top: 0;
    bottom: 0;
    max-height: 100%;
    overflow: hidden;
  }
  &:not(.fc-event-start) {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:not(.fc-event-end) {
    border-bottom-width: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.fc-event-selected {
    &:before {
      left: -10px;
      right: -10px;
    }
    .fc-event-resizer {
      left: 50%;
      margin-left: -4px;
      margin-left: calc(var(--fc-event-resizer-dot-total-width, 8px) / -2);
    }
    .fc-event-resizer-start {
      top: -4px;
      top: calc(var(--fc-event-resizer-dot-total-width, 8px) / -2);
    }
    .fc-event-resizer-end {
      bottom: -4px;
      bottom: calc(var(--fc-event-resizer-dot-total-width, 8px) / -2);
    }
  }
  .fc-event-resizer-start {
    cursor: n-resize;
  }
  .fc-event-resizer-end {
    cursor: s-resize;
  }
  &:not(.fc-event-selected) {
    .fc-event-resizer {
      height: 8px;
      height: var(--fc-event-resizer-thickness, 8px);
      left: 0;
      right: 0;
    }
    .fc-event-resizer-start {
      top: -4px;
      top: calc(var(--fc-event-resizer-thickness, 8px) / -2);
    }
    .fc-event-resizer-end {
      bottom: -4px;
      bottom: calc(var(--fc-event-resizer-thickness, 8px) / -2);
    }
  }
}
.fc .fc-timegrid-axis-chunk > table,
.fc .fc-timegrid-body,
.fc .fc-timegrid-slots {
  position: relative;
  z-index: 1;
}
.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  padding: 0 4px;
}
.fc-media-screen {
  .fc-timegrid-cols {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    & > table {
      height: 100%;
    }
  }
  .fc-timegrid-event-harness {
    position: absolute;
  }
  .fc-timegrid-event {
    position: absolute;
    top: 0;
    bottom: 1px;
    left: 0;
    right: 0;
  }
}
.fc-media-screen .fc-timegrid-col-bg,
.fc-media-screen .fc-timegrid-col-events,
.fc-media-screen .fc-timegrid-now-indicator-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc .fc-timegrid-col-bg .fc-highlight,
.fc .fc-timegrid-col-events {
  z-index: 5 !important;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror {
  box-shadow: 0 0 0 1px #fff;
  box-shadow: 0 0 0 1px var(--fc-page-bg-color, #fff);
}
.fc-timegrid-event {
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
  border-radius: 3px;
  .fc-event-main {
    padding: 1px 1px 0;
  }
  .fc-event-time {
    white-space: nowrap;
    font-size: 0.85em;
    font-size: var(--fc-small-font-size, 0.85em);
    margin-bottom: 1px;
  }
}
.fc-timegrid-event-condensed {
  .fc-event-main-frame {
    flex-direction: row;
    overflow: hidden;
  }
  .fc-event-time {
    &:after {
      content: '\00a0-\00a0';
    }
  }
  .fc-event-title {
    font-size: 0.85em;
    font-size: var(--fc-small-font-size, 0.85em);
  }
}
.fc .fc-list-day-cushion,
.fc .fc-list-table td {
  padding: 8px 14px;
}
.fc-direction-ltr .fc-list-day-text,
.fc-direction-rtl .fc-list-day-side-text {
  float: left;
}
.fc-direction-ltr .fc-list-day-side-text,
.fc-direction-rtl .fc-list-day-text {
  float: right;
}
.fc .fc-list-event-graphic,
.fc .fc-list-event-time {
  white-space: nowrap;
  width: 1px;
}
.fc-theme-bootstrap {
  a {
    &:not([href]) {
      color: inherit;
    }
  }
}
.calendar_el {
  th {
    &.fc-col-header-cell {
      & > div {
        & > a {
          width: 95%;
          margin: 0 auto;
        }
      }
    }
  }
}

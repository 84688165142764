.btn {
  position: relative;
  text-align: center;
  height: 38px;
  border-radius: 6px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
}

.btn__label {
  display: inline-block;
  margin: 0 3rem;
}

.btn__icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-left: 1.3rem;
}

.btn__icon+.btn__label {
  margin-left: 1.2rem;
}

.btn--full {
  width: 100%;
}

.btn--full .btn__label {
  margin: 0 auto;
}

.btn--middle {
  height: 5rem;
}

.btn--middle.btn__label {
  line-height: 5rem;
  margin: 0 2.5rem;
}

.btn--blue {
  background: $blueColor;
  color: #fff;
}

.btn--blue.wait {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
}

.btn--blue.isDisabled {
  cursor: not-allowed;
  opacity: 0.5;
  color: #c4c4c4;
  pointer-events: none;
  text-decoration: none;
}

.btn--border {
  border: 1px solid #e2e2ea;
}

@media action {
  .btn--border:hover {
    background: #f5f5f5;
  }
}

.btn--circle {
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
  text-align: center;
  line-height: 3.6rem;
}

.btn--circle .btn__label {
  width: 100%;
  margin: 0;
  text-align: center;
  font-size: 2.6rem;
  font-weight: 400;
  color: #44444f;
  line-height: 3.6rem;
}

.btn--blue-border {
  background: #fff;
  border: 1px solid $blueColor;
}

.btn--blue-border.btn--blue-border-mobile {
  margin: 0 1rem;
  width: 100%;
  margin-bottom: 20px !important;
}

.btn--blue-border.wait {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
  color: $blueColor;
}

.btn--blue-border .btn__label {
  color: $blueColor;
}

.btn--blue-border .btn__label.btn__label-mobile {
  margin: 0 1rem;
}

.btn--red-border {
  background: #fff;
  color: #fff;
  border: 1px solid $redColor;
  color: $redColor;
}

.btn--grey-border {
  border: 1px solid #95a5b8;
  color: #000;
}

.btn--white {
  color: #000;
  background: #fff;
}

.btn--red {
  color: #fff;
  background: $redColor;
}

.btn--green {
  color: #fff;
  background: $greenColor;
}

.disable {
  background: #999;
}

.disabled,
[disabled] {
  opacity: 0.67;
  cursor: not-allowed;
}